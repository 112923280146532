import React from "react"
import Table from "react-bootstrap/Table"
import { vatApplicable, formatNumber, getTotal } from "../../../state/utils"
import styles from "../../../styles/components/documentPreview.module.scss"

const IssueReplacementPreview = ({ data }) => {
  const isVatApplicable = vatApplicable(data.date)
  const calculateTotalPrice = (
    price,
    qty,
    discount,
    discountType,
    newWarranty
  ) => {
    let discountAmount

    if (newWarranty) {
      discountAmount =
        discountType === "LKR"
          ? discount * qty || 0
          : (price * qty * discount || 0) / 100
    } else {
      discountAmount =
        discountType === "LKR"
          ? discount || 0
          : (price * qty * discount || 0) / 100
    }

    // const addAmountForWarranty=newWarranty && newWarranty == '5 Years' ? (parseFloat(price) * 15/100) * parseFloat(qty) : 0

    if (isNaN(price) || isNaN(qty)) {
      return `Rs. 0`
    } else {
      return `Rs. ${formatNumber(price * qty - discountAmount)}`
    }
  }

  return (
    <div className={styles.invoicePreview}>
      {/* Replacement Details Section */}
      <div className={styles.replacementDetails}>
        <div className={styles.detailRow}>
          <span className={styles.label}>Replacement Method:</span>
          <span className={styles.value}>{data.replacementMethod}</span>
        </div>
        <div className={styles.detailRow}>
          <span className={styles.label}>Warranty Claim Number:</span>
          <span className={styles.value}>{data.warrantyClaimNumber}</span>
        </div>
        <div className={styles.detailRow}>
          <span className={styles.label}>Technical Team Member:</span>
          <span className={styles.value}>{data.technicalTeamMember}</span>
        </div>
        <div className={styles.detailRow}>
          <span className={styles.label}>Customer Name:</span>
          <span className={styles.value}>{data.customerName}</span>
        </div>
        <div className={styles.detailRow}>
          <span className={styles.label}>Faulty Serial Number:</span>
          <span className={styles.value}>{data.faultySerialNumber}</span>
        </div>
        <div className={styles.detailRow}>
          <span className={styles.label}>Faulty Inverter Received:</span>
          <span className={styles.value}>
            {data.faultyInverterReceived ? "Yes" : "No"}
          </span>
        </div>
        <div className={styles.detailRow}>
          <span className={styles.label}>Letter Received:</span>
          <span className={styles.value}>
            {data.letterReceived ? "Yes" : "No"}
          </span>
        </div>
        <div className={styles.detailRow}>
          <span className={styles.label}>Faulty Details:</span>
          <span className={styles.value}>{data.faultyDetails}</span>
        </div>
      </div>

      {/* Item Info */}
      <div className={styles.itemTable}>
        <Table responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Item Description</th>
              <th>Image</th>
              <th>Qty.</th>
              <th>Unit Price</th>
              <th>Disc.</th>
              <th>Total Disc.</th>
              <th>Amount (LKR)</th>
            </tr>
          </thead>
          <tbody>
            {data?.replacementProducts?.map((product, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>
                  <p>{product.productName}</p>
                  {product.productDescription !== "N/A" && (
                    <p>{product.productDescription}</p>
                  )}
                  <p>{product.sku}</p>
                </td>
                <td>
                  {product.productImages && product.productImages[0] ? (
                    <img
                      src={product.productImages[0].fields.file.url}
                      className={styles.itemImg}
                      alt={`${product.productName}`}
                    />
                  ) : (
                    <p>N/A</p>
                  )}
                </td>
                <td>{product.quantity}</td>
                <td>Rs. {formatNumber(product.price)}</td>
                <td>
                  {product.discount === 0
                    ? "-"
                    : product.discountType === "%"
                    ? `${product.discount}%`
                    : `Rs.${formatNumber(product.discount)}`}
                </td>
                <td>
                  {product.discount === 0
                    ? "-"
                    : product.discountType === "%"
                    ? `Rs. ${formatNumber(
                        product.price *
                          product.quantity *
                          (Number(product.discount) / 100)
                      )}`
                    : `Rs. ${formatNumber(product.discount)}`}
                </td>

                <td>
                  {calculateTotalPrice(
                    product.price,
                    product.quantity,
                    product.discount,
                    product.discountType,
                    product.warrantyPeriod
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          {data?.replacementProducts?.length > 0 && (
            <tfoot>
              <tr>
                <td
                  colSpan="7"
                  style={{
                    textAlign: "right",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  Total
                </td>
                <td>Rs. {formatNumber(getTotal(data.replacementProducts))}</td>
              </tr>
            </tfoot>
          )}
        </Table>
      </div>
      {/* Serial Numbers Section */}

      {data.replacementProducts.some(
        product => product.serialNumbers?.length > 0
      ) && (
        <div className="mt-3">
          <h3>Serial Numbers</h3>
          <Table responsive>
            <thead>
              <tr>
                <th>Item</th>
                <th>Serial Numbers</th>
              </tr>
            </thead>
            <tbody>
              {data?.replacementProducts?.map((product, idx) => {
                if (
                  !product.serialNumbers ||
                  product.serialNumbers.length === 0
                )
                  return null

                return (
                  <tr key={idx}>
                    <td>
                      <p>{product.productName}</p>
                      {product.productDescription !== "N/A" && (
                        <p>{product.productDescription}</p>
                      )}
                      <p>{product.sku}</p>
                    </td>
                    <td>
                      {product.serialNumbers.map(serial => (
                        <span
                          key={serial.main}
                          className="mr-3"
                          style={{ backgroundColor: "#eee" }}
                        >
                          {serial.main}{" "}
                          {product.category === "Solar Inverter" &&
                            serial.complimentary &&
                            `(WiFi Stick Serial: ${serial.complimentary})`}
                        </span>
                      ))}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  )
}

export default IssueReplacementPreview
