import React, { useState, useEffect } from "react"
import { navigate } from "@reach/router"
import { connect } from "react-redux"
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap"
import {
  createTroubleshooting,
  updateTroubleshootingById,
  getTroubleshootingById,
  deleteTroubleshooting,
  updateTroubleshootingStatus,
  confirmReplacement,
  setCurrentTroubleshooting,
} from "../../../state/actions/troubleShootings"
import { getInvoiceBySerialNumber } from "../../../state/actions/invoices"
import { setUsers } from "../../../state/actions/user"
import TroubleshootingPdf from "./trsfPDF"
import WarrantyReplacementPDF from "../warrantyReplacement/warrantyReplacementPDF"
import BackButton from "../../backButton"
import { toast } from "react-toastify"
import { confirmAlert } from "react-confirm-alert"
import { ERROR_CODES } from "../invErrorCodes"

import styles from "../../../styles/components/quotations/newQuotation.module.scss"

import { FaSearch, FaEdit, FaTimes, FaTrash } from "react-icons/fa"

const TroubleshootingForm = ({
  id,
  getInvoiceBySerialNumber,
  createTroubleshooting,
  updateTroubleshootingById,
  getTroubleshootingById,
  deleteTroubleshooting,
  updateTroubleshootingStatus,
  confirmReplacement,
  loading,
  error,
  currentTroubleshooting,
  troubleshootings,
  products,
  customers,
  user,
  setCurrentTroubleshooting,
}) => {
  console.log(troubleshootings)

  const [searchSerialNumber, setSearchSerialNumber] = useState("")

  const [formError, setFormError] = useState(null)

  const [isEditing, setIsEditing] = useState(!id)

  const [showTSPDF, setShowTSPDF] = useState(false)

  const [showWarrantyPDF, setShowWarrantyPDF] = useState(false)

  const [commentCount, setCommentCount] = useState(1)

  const [formData, setFormData] = useState({
    reportDate: "",
    installationDate: "",
    installerCompanyName: "",
    distributorCompanyName: "Gunda Power Private Limited",
    invoiceNumber: "",
    errorDisplay: "",
    country: "Sri Lanka",
    city: "",
    gridStandard: "USER-DEF",

    modelType: "",
    serialNumber: "",
    softwareVersion: "N/A",
    dcIsoYes: false,
    dcIsoNo: false,

    errorCode: "",
    errorMessage: "",

    ledLightStatusPower: false,
    ledLightStatusOperation: false,
    ledLightStatusAlarm: false,

    displayStatusOn: false,
    displayStatusOff: false,

    acVoltageL1: "",
    acVoltageL2: "",
    acVoltageL3: "",
    acVoltageNPE: "",

    gridConnectionSingle: false,
    gridConnectionThree: false,
    gridConnectionTT: false,
    gridConnectionIT: false,
    gridConnectionTN: false,

    pvVoltageDc1: "",
    pvVoltageDc2: "",
    pvVoltageDc3: "",
    pvVoltageDc4: "",

    PvStringVoltage: Array(10).fill(false),

    // Technical Details
    technicianName: "",
    technicianPhone: "",
    technicianEmail: "",
    technicianSignature: "",

    // Installer Comments
    comment1: "",
    comment2: "",
    comment3: "",
    comment4: "",
    comment5: "",

    // USER (Thilina, Chandana and etc.)
    userName: user.name,
    userEmail: user.email,
    userPhone: user.phone,
  })

  // Search Invoice by  S/N /// 1031190233310024

  const handleSerialNumberSearch = async e => {
    e.preventDefault()

    if (!searchSerialNumber.trim()) {
      toast.warning("Please enter a serial number to search")
      return
    }

    if (troubleshootings.find(ts => ts.serialNumber === searchSerialNumber)) {
      toast.error("This S/N has already been entered")
      return
    }

    try {
      const invoiceData = await getInvoiceBySerialNumber(searchSerialNumber)

      // Auto-fill form fields if invoice is found
      if (invoiceData) {
        const matchedProduct = products?.find(prod =>
          invoiceData.invoiceProducts?.some(invProd => invProd.sku === prod.sku)
        )

        const matchedCustomer = customers?.find(
          cust => invoiceData.customerCode === cust.code
        )

        setFormData(prevState => ({
          ...prevState,
          serialNumber: searchSerialNumber,
          modelType: matchedProduct?.productName || "",
          invoiceNumber: invoiceData?.invoiceNumber || "",
          installerCompanyName: matchedCustomer?.name,
        }))

        toast.success("Invoice found and form updated!")
      }
    } catch (error) {
      console.error("Search error:", error)
    }
  }

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          await getTroubleshootingById(id)
        } catch (err) {
          setFormError("Failed to fetch troubleshooting data")
          console.error(err)
        }
      }
      fetchData()
    }
  }, [id, getTroubleshootingById])

  useEffect(() => {
    setUsers()
  }, [])

  useEffect(() => {
    if (id && currentTroubleshooting) {
      setFormData({
        ...currentTroubleshooting,
        reportDate: currentTroubleshooting.reportDate
          ? new Date(currentTroubleshooting.reportDate)
              .toISOString()
              .split("T")[0]
          : "",
        installationDate: currentTroubleshooting.installationDate
          ? new Date(currentTroubleshooting.installationDate)
              .toISOString()
              .split("T")[0]
          : "",
      })
    }
  }, [id, currentTroubleshooting])

  const handleErrorCodeChange = e => {
    const code = e.target.value
    setFormData(prev => ({
      ...prev,
      errorCode: code,
      errorMessage: ERROR_CODES[code] || "",
    }))
  }

  const handleAddComment = () => {
    if (commentCount < 5) {
      setCommentCount(commentCount + 1)
    }
  }

  const handleCommentChange = (e, index) => {
    const { value } = e.target
    if (value.length <= 116) {
      setFormData({
        ...formData,
        [`comment${index + 1}`]: value,
      })
    }
  }

  const renderCommentInputs = () => {
    const inputs = []

    for (let i = 0; i < commentCount; i++) {
      inputs.push(
        <Col key={i} xs={12} className="mb-3">
          <InputGroup size="lg">
            <Form.Control
              type="text"
              placeholder={`Comment ${i + 1}`}
              value={formData[`comment${i + 1}`]}
              onChange={e => handleCommentChange(e, i)}
              disabled={!isEditing}
            />
            <InputGroup.Text>
              {formData[`comment${i + 1}`]?.length || 0}/116
            </InputGroup.Text>
          </InputGroup>
        </Col>
      )
    }
    return inputs
  }

  const handleChange = e => {
    const { name, value, type, checked } = e.target
    setFormData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const handleStringVoltageChange = index => {
    setFormData(prevState => {
      const newPvStringVoltage = [...prevState.PvStringVoltage]
      newPvStringVoltage[index] = !newPvStringVoltage[index]
      return {
        ...prevState,
        PvStringVoltage: newPvStringVoltage,
      }
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setFormError(null)

    try {
      if (id) {
        await updateTroubleshootingById(id, formData)
        toast.success("Success: Troubleshooting updated!")
      } else {
        await createTroubleshooting(formData)
        toast.success("Success: Troubleshooting Form Created!")
        navigate("/app/troubleshoots")
      }
    } catch (err) {
      setFormError(err.message || "An error occurred while submitting the form")
      console.error("Error submitting form:", err)
    }
  }

  const handleStatusUpdate = () => {
    confirmAlert({
      title: "Update Status",
      message:
        "Are you sure you want to mark this troubleshooting as completed?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await updateTroubleshootingStatus(id, "Completed")
            } catch (error) {
              console.error("Failed to update status:", error)
            }
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  const handleCancel = () => {
    confirmAlert({
      title: "Cancel Troubleshooting",
      message: "Are you sure you want to cancel this troubleshooting?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await updateTroubleshootingStatus(id, "Cancelled")
            } catch (error) {
              console.error("Failed to cancel troubleshooting:", error)
            }
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  const handleReplacementConfirmation = () => {
    confirmAlert({
      title: "Confirm Replacement",
      message: "Are you sure you want to confirm replacement?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await confirmReplacement(id)
            } catch (error) {
              console.error("Replacement confirmation failed:", error)
            }
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  useEffect(() => {
    setCurrentTroubleshooting(currentTroubleshooting)
  }, [])

  const handleIssueReplacement = () => {
    navigate(`/app/issue-replacement-form/`)
  }

  useEffect(() => {
    if (error) {
      toast.error(error)
    }
  }, [error])

  // Toggle Edit
  const toggleEditMode = () => {
    setIsEditing(!isEditing)
  }

  const handleDelete = () => {
    confirmAlert({
      title: "Delete Troubleshooting",
      message: "Are you sure you want to delete this form?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const isDeleted = await deleteTroubleshooting(id)
              if (isDeleted) {
                navigate("/app/troubleshoots")
              } else {
                toast.error("Failed to delete troubleshooting.")
              }
            } catch (error) {
              console.error("Deletion failed:", error)
            }
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className="d-flex justify-content-between align-items-center">
        {currentTroubleshooting ? (
          <h1 className="">
            {currentTroubleshooting?.trsfNumber} (
            {currentTroubleshooting?.status === "Cancelled" ? (
              <span className="text-danger">Cancelled</span>
            ) : currentTroubleshooting?.status === "Issued" ? (
              <span className="text-success">Issued</span>
            ) : currentTroubleshooting?.confirmReplacement ? (
              <span className="">Replacement Confirmed</span>
            ) : (
              currentTroubleshooting?.status
            )}
            )
          </h1>
        ) : (
          <h1 className="title">TROUBLESHOOTING PROCEDURES</h1>
        )}
        {id && (
          <div>
            <Button
              variant={isEditing ? "secondary" : "primary"}
              onClick={toggleEditMode}
              className="ms-3"
            >
              {isEditing ? (
                <>
                  <FaTimes className="mr-1" />
                  Cancel Edit
                </>
              ) : (
                <>
                  <FaEdit className="mr-1" />
                  Enable Edit
                </>
              )}
            </Button>

            {/* <Button variant="danger" onClick={handleDelete} className="m-3">
              <>
                <FaTrash className="mr-1" />
                Delete
              </>
            </Button> */}
          </div>
        )}
      </div>
      <BackButton to="/app/troubleshoots" />

      {/* Action Buttons */}
      {id && (
        <>
          <div className="action-buttons d-flex mt-4">
            {showTSPDF && (
              <div className="mr-4">
                <TroubleshootingPdf data={currentTroubleshooting} />
              </div>
            )}

            <Button
              variant="primary"
              onClick={() => setShowTSPDF(true)}
              style={{ display: showTSPDF ? "none" : "block" }}
              className="mr-4"
            >
              Generate TS PDF
            </Button>

            {currentTroubleshooting?.status !== "Cancelled" && (
              <>
                {currentTroubleshooting?.confirmReplacement && (
                  <Button
                    variant="primary"
                    onClick={() => setShowWarrantyPDF(true)}
                    style={{ display: showWarrantyPDF ? "none" : "block" }}
                    className="mr-4"
                  >
                    Generate Warranty Form
                  </Button>
                )}

                {currentTroubleshooting?.confirmReplacement && showWarrantyPDF && (
                  <div className="mr-4">
                    <WarrantyReplacementPDF data={currentTroubleshooting} />
                  </div>
                )}
              </>
            )}

            {currentTroubleshooting?.status === "In Progress" && (
              <Button
                variant="secondary"
                type="submit"
                className="mr-4"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            )}

            {currentTroubleshooting?.status === "In Progress" &&
              !currentTroubleshooting?.confirmReplacement && (
                <Button
                  variant="secondary"
                  type="submit"
                  className="mr-4"
                  onClick={handleReplacementConfirmation}
                >
                  Confirm Replacement
                </Button>
              )}
            {currentTroubleshooting?.status === "In Progress" &&
              currentTroubleshooting?.confirmReplacement && (
                <Button
                  variant="secondary"
                  onClick={handleIssueReplacement}
                  className="mr-4"
                >
                  Issue Replacement
                </Button>
              )}
          </div>
        </>
      )}

      {/* Serial Number Search Section */}

      {!id && (
        <Form onSubmit={handleSerialNumberSearch} className="mt-4 mb-5">
          <Row>
            <Col lg={4} md={8} sm={12}>
              <Form.Label>Search Invoice Data by the Serial Number</Form.Label>
              <InputGroup size="lg">
                <Form.Control
                  type="text"
                  placeholder="Search by Serial Number"
                  value={searchSerialNumber}
                  onChange={e => setSearchSerialNumber(e.target.value)}
                />
                <Button variant="primary" type="submit">
                  <FaSearch /> Search
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      )}

      <Form className="my-5" onSubmit={handleSubmit}>
        <Row>
          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Report Date</Form.Label>
              <Form.Control
                size="lg"
                type="date"
                name="reportDate"
                value={formData.reportDate}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Installation Date</Form.Label>
              <Form.Control
                size="lg"
                type="date"
                name="installationDate"
                value={formData.installationDate}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Installer Company Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="installerCompanyName"
                value={formData.installerCompanyName}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Distributor Company Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="distributorCompanyName"
                value={formData.distributorCompanyName}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Invoice Number</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="invoiceNumber"
                value={formData.invoiceNumber}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          {/* <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Error Display</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="errorDisplay"
                value={formData.errorDisplay}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col> */}

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Grid Standard</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="gridStandard"
                value={formData.gridStandard}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          {/* Faulty Inverter Details */}
          <Col xs={12}>
            <hr className="my-5" />
          </Col>

          <Col xs={12}>
            <h3 className="title mb-3">Faulty Inverter Details</h3>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Model Type</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="modelType"
                value={formData.modelType}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Serial Number</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="serialNumber"
                value={formData.serialNumber}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Software Version</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="softwareVersion"
                value={formData.softwareVersion}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          {/* Display Status */}

          <Col xs={12}>
            <p className="my-3">Built in DC isolator</p>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3 d-flex justify-content-between">
              <Form.Check
                label="Yes"
                name="dcIsoYes"
                type="checkbox"
                checked={formData.dcIsoYes}
                inline
                onChange={handleChange}
                disabled={!isEditing}
              />

              <Form.Check
                label="No"
                name="dcIsoNo"
                type="checkbox"
                checked={formData.dcIsoNo}
                inline
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          {/* Failure Description */}
          <Col xs={12}>
            <hr className="my-5" />
          </Col>

          <Col xs={12}>
            <h3 className="title mb-3">Failure Description</h3>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Error Code</Form.Label>
              <select
                size="lg"
                name="errorCode"
                value={formData.errorCode}
                onChange={handleErrorCodeChange}
                disabled={!isEditing}
                style={{
                  display: "block",
                  padding: 4,
                  borderColor: "#C7C8CC",
                  width: "100%",
                }}
              >
                <option value="">Select Error Code</option>
                {Object.entries(ERROR_CODES).map(([code, message]) => (
                  <option key={code} value={code}>
                    {code}
                  </option>
                ))}
              </select>
            </Form.Group>
          </Col>
          <Col lg={8} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Error Message</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="errorMessage"
                value={formData.errorMessage}
                disabled={true}
              />
            </Form.Group>
          </Col>

          {/* LED Light Status */}

          <Col xs={12}>
            <p className="title my-3">LED Light Status</p>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Check
                label="Power"
                name="ledLightStatusPower"
                type="checkbox"
                inline
                checked={formData.ledLightStatusPower}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Check
                label="Operation"
                name="ledLightStatusOperation"
                type="checkbox"
                inline
                checked={formData.ledLightStatusOperation}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Check
                label="Alarm"
                type="checkbox"
                inline
                name="ledLightStatusAlarm"
                checked={formData.ledLightStatusAlarm}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          {/* Display Status */}

          <Col xs={12}>
            <p className="title my-3">Display Status</p>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3 d-flex justify-content-between">
              <Form.Check
                label="On"
                name="displayStatusOn"
                type="checkbox"
                checked={formData.displayStatusOn}
                inline
                onChange={handleChange}
                disabled={!isEditing}
              />

              <Form.Check
                label="Off"
                name="displayStatusOff"
                type="checkbox"
                checked={formData.displayStatusOff}
                inline
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          {/* AC Voltage */}

          <Col xs={12}>
            <p className="title my-3">AC Voltage</p>
          </Col>

          <Col lg={3} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>L1</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="acVoltageL1"
                value={formData.acVoltageL1}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={3} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>L2</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="acVoltageL2"
                value={formData.acVoltageL2}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={3} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>L3</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="acVoltageL3"
                value={formData.acVoltageL3}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={3} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>NPE</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="acVoltageNPE"
                value={formData.acVoltageNPE}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col xs={12}>
            <p className="title my-3">PV Voltage</p>
          </Col>

          {/* PV Voltage */}

          <Col lg={3} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>DC1</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="pvVoltageDc1"
                value={formData.pvVoltageDc1}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={3} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>DC2</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="pvVoltageDc2"
                value={formData.pvVoltageDc2}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={3} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>DC3</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="pvVoltageDc3"
                value={formData.pvVoltageDc3}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={3} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>DC4</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="pvVoltageDc4"
                value={formData.pvVoltageDc4}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          {/* Grid Connection Type */}

          <Col xs={12}>
            <p className="title my-3">Grid Connection Type</p>
          </Col>

          <Col className="d-flex justify-content-between">
            <Form.Check
              name="gridConnectionSingle"
              inline
              type="checkbox"
              label="Single Phase"
              checked={formData.gridConnectionSingle}
              onChange={handleChange}
              disabled={!isEditing}
            />
            <Form.Check
              name="gridConnectionThree"
              inline
              type="checkbox"
              label="Three Phase"
              checked={formData.gridConnectionThree}
              onChange={handleChange}
              disabled={!isEditing}
            />
            <Form.Check
              name="gridConnectionTT"
              inline
              type="checkbox"
              label="TT (5Wire)"
              checked={formData.gridConnectionTT}
              onChange={handleChange}
              disabled={!isEditing}
            />
            <Form.Check
              name="gridConnectionTN"
              inline
              type="checkbox"
              label="TN (4Wire)"
              checked={formData.gridConnectionTN}
              onChange={handleChange}
              disabled={!isEditing}
            />
            <Form.Check
              name="gridConnectionIT"
              inline
              type="checkbox"
              label="IT (3Wire)"
              checked={formData.gridConnectionIT}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </Col>
        </Row>

        {/* Pv String Voltage Checkboxes */}
        {/* <Row className="mt-2">
          <Col xs={12}>
            <p className="title my-3">Pv String Voltage</p>
          </Col>
          <Col>
            <Row>
              {formData.PvStringVoltage.map((checked, index) => (
                <Col key={index} lg={2} md={3} sm={4} xs={6}>
                  <Form.Check
                    inline
                    type="checkbox"
                    label={`String ${index + 1}`}
                    checked={checked}
                    onChange={() => handleStringVoltageChange(index)}
                    disabled={!isEditing}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row> */}

        <Row className="mt-3">
          <Col xs={12}>
            <p className="title my-3">Technical Details</p>
          </Col>

          <Col lg={3} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Technician Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="technicianName"
                value={formData.technicianName}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={3} md={6} sm={12}>
            {/* Technician Phone */}
            <Form.Group className="mb-3">
              <Form.Label>Technician Phone</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="technicianPhone"
                value={formData.technicianPhone}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={3} md={6} sm={12}>
            {/* Technician Email */}
            <Form.Group className="mb-3">
              <Form.Label>Technician Email</Form.Label>
              <Form.Control
                size="lg"
                type="email"
                name="technicianEmail"
                value={formData.technicianEmail}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>

          <Col lg={3} md={6} sm={12}>
            {/* Technician Signature */}
            <Form.Group className="mb-3">
              <Form.Label>Technician Signature</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="technicianSignature"
                value={formData.technicianSignature}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col xs={12}>
            <p className="my-3">
              <strong>Note:</strong> Any other Failures Please share the Photos.
            </p>
          </Col>
        </Row>

        {/* Installer Comments */}

        <Row className="mt-3">
          <Col xs={12}>
            <p className="title my-3">Installer Comments</p>
          </Col>

          {isEditing && renderCommentInputs()}
          <Col xs={12}>
            {!isEditing && currentTroubleshooting?.comment1 && (
              <p>
                <strong>Comment 1: </strong> {currentTroubleshooting?.comment1}
              </p>
            )}
            {!isEditing && currentTroubleshooting?.comment2 && (
              <p>
                <strong>Comment 2: </strong> {currentTroubleshooting?.comment2}
              </p>
            )}
            {!isEditing && currentTroubleshooting?.comment3 && (
              <p>
                <strong>Comment 3: </strong> {currentTroubleshooting?.comment3}
              </p>
            )}
            {!isEditing && currentTroubleshooting?.comment4 && (
              <p>
                <strong>Comment 4: </strong> {currentTroubleshooting?.comment4}
              </p>
            )}
            {!isEditing && currentTroubleshooting?.comment5 && (
              <p>
                <strong>Comment 5: </strong> {currentTroubleshooting?.comment5}
              </p>
            )}
          </Col>

          {isEditing && commentCount < 5 && (
            <Col xs={12}>
              <Button variant="secondary" onClick={handleAddComment}>
                Add Comment
              </Button>
            </Col>
          )}
        </Row>

        {/* Submit Button */}

        <Row className="mt-5">
          {isEditing && (
            <Button
              className={styles.btn}
              variant="primary"
              type="submit"
              disabled={loading}
            >
              Submit
            </Button>
          )}
        </Row>
      </Form>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    loading: state.troubleshooting.editTroubleshootingLoading,
    error: state.troubleshooting.error,
    currentTroubleshooting: state.troubleshooting.troubleshootings?.find(
      trs => trs._id === props.id
    ),
    replacementProducts: state.product.replacementProducts,
    products: state.product.products,

    customers: state.customer.customers,
    user: state.auth.auth.user,

    troubleshootings: state.troubleshooting.troubleshootings,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getInvoiceBySerialNumber: sn => dispatch(getInvoiceBySerialNumber(sn)),
    createTroubleshooting: data => dispatch(createTroubleshooting(data)),
    getTroubleshootingById: id => dispatch(getTroubleshootingById(id)),
    updateTroubleshootingById: (id, data) =>
      dispatch(updateTroubleshootingById(id, data)),
    deleteTroubleshooting: id => dispatch(deleteTroubleshooting(id)),
    updateTroubleshootingStatus: (id, status) =>
      dispatch(updateTroubleshootingStatus(id, status)),
    confirmReplacement: id => dispatch(confirmReplacement(id)),

    setCurrentTroubleshooting: data =>
      dispatch(setCurrentTroubleshooting(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TroubleshootingForm)
