import { forEach } from "lodash"
import React, { useState } from "react"
import { Form, Modal, Button, Table } from "react-bootstrap"
import { FaWindowClose } from "react-icons/fa"
import { connect } from "react-redux"
import TagsInput from "react-tagsinput"
import { toast } from "react-toastify"
import { updateStock } from "../../state/actions/product"
import { updateReplacementStock } from "../../state/actions/replacementProduct"
import FetchExcel from "../fetchExcel"

import styles from "../../styles/components/inventory/addStock.module.scss"

const AddStock = ({
  product,
  updateStock,
  updateReplacementStock,
  document,
}) => {
  const [show, setShow] = useState(false)
  const [stock, setStock] = useState(0)
  const [includeSerialNumbers, setIncludeSerialNumbers] = useState(false)
  const [serialNumbers, setSerialNumbers] = useState([])

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const serialNumberList = product.serialNumbers?.map(serial => serial?.main)

  const handleChange = (tags, changed) => {
    let duplicate = false
    changed.forEach(change => {
      if (serialNumberList.includes(change.split(/\s+/)[0])) {
        duplicate = true
        toast.error(`The serial number ${change} already exists`)
      }
    })

    if (duplicate) return

    setSerialNumbers([...new Set(tags)])
  }

  const handleSubmit = () => {
    const newSerialNumbers = [...product.serialNumbers]
    const newStock = +product.stock + +stock

    if (product.category === "Solar Inverter") {
      serialNumbers.forEach(serialNumber => {
        // Split serialnumber by whitespace
        const newSerialNumber = serialNumber.split(/\s+/)
        newSerialNumbers.push({
          main: newSerialNumber[0],
          complimentary: newSerialNumber[1],
        })
      })
    } else if (product.category === "PV Modules") {
      serialNumbers.forEach(serialNumber => {
        // Split serialnumber by whitespace
        const newSerialNumber = serialNumber.split(/\s+/)
        newSerialNumbers.push({
          main: newSerialNumber[0],
          palletNumber: newSerialNumber[1],
        })
      })
    } else {
      serialNumbers.forEach(serialNum =>
        newSerialNumbers.push({ main: serialNum })
      )
    }

    {
      document === "product" &&
        updateStock({
          productSKU: product.sku,
          stock: newStock,
          serialNumbers: newSerialNumbers,
        })
    }

    {
      document === "replacementProduct" &&
        updateReplacementStock({
          productSKU: product.sku,
          stock: newStock,
          serialNumbers: newSerialNumbers,
        })
    }
  }

  // For react-tagsinput
  const pasteSplit = data => {
    const separators = ["\r", "\n"]
    return data.split(new RegExp(separators.join("|"))).map(d => d.trim())
  }

  // Function to handle data processed from FetchExcel
  const handleDataProcessed = combinedData => {
    setSerialNumbers(combinedData.split("\n").map(str => str.trim()))
  }

  return (
    <>
      <Button
        onClick={handleShow}
        onKeyDown={handleShow}
        tabIndex={0}
        size="lg"
        variant="primary"
      >
        Add Stock
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Add New Stock</Modal.Title>
          <Button
            tabIndex={2}
            variant="danger"
            onClick={handleClose}
            onKeyDown={handleClose}
          >
            <FaWindowClose />
          </Button>
        </Modal.Header>
        <Modal.Body>
          {product.productImages &&
            product.productImages.map((image, i) => (
              <div key={i} className="text-center">
                <img
                  className="m-4"
                  src={image.fields.file.url}
                  alt={image.fields.description}
                  style={{
                    maxHeight: "150px",
                  }}
                />
              </div>
            ))}

          <Table>
            <tbody>
              <tr>
                <td className={styles.label}>SKU</td>
                <td> {product.sku}</td>
              </tr>
              <tr>
                <td className={styles.label}>Title</td>
                <td>{product.productName}</td>
              </tr>
              <tr>
                <td className={styles.label}>Description</td>
                <td>{product.productDescription}</td>
              </tr>

              <tr>
                <td className={styles.label}>Category</td>
                <td>{product.category}</td>
              </tr>

              <tr>
                <td className={styles.label}>Manufacturer</td>
                <td>{product.manufacturer}</td>
              </tr>

              <tr>
                <td className={styles.label}>Warranty</td>
                <td>{product.warranty}</td>
              </tr>

              <tr>
                <td className={styles.label}>Country of Origin</td>
                <td>{product.countryOfOrigin}</td>
              </tr>

              <tr>
                <td className={styles.label}>Stock in Hand</td>
                <td>{product.stock}</td>
              </tr>

              <tr>
                <td className={styles.label}>Stock in Transit</td>
                <td>{product.stockInTransit}</td>
              </tr>
            </tbody>
          </Table>

          <Form className="mt-5">
            <strong>New Stock</strong>
            <Form.Group controlId="stock">
              <Form.Label>Qty:</Form.Label>
              <Form.Control
                size="lg"
                type="number"
                placeholder="Enter New Stock"
                value={stock}
                onChange={e => {
                  if (e.target.value < 0) {
                    return
                  }
                  setStock(e.target.value)
                }}
              />
            </Form.Group>
            <Form.Group controlId="serialCheckbox" className="mt-2">
              <Form.Check
                className={styles.serialNumberCheckbox}
                type="checkbox"
                label="Include Serial Numbers"
                checked={includeSerialNumbers}
                onChange={() => setIncludeSerialNumbers(!includeSerialNumbers)}
              />
            </Form.Group>
            {includeSerialNumbers && (
              <Form.Group
                className="mt-2 px-2 border "
                controlId="serialNumbers"
              >
                <div className="my-3">
                  <p>Import the flash report</p>
                  <FetchExcel onDataProcessed={handleDataProcessed} />
                </div>
                <Form.Label>Serial Numbers:</Form.Label>
                <TagsInput
                  value={serialNumbers}
                  onChange={handleChange}
                  pasteSplit={pasteSplit}
                  addOnPaste={true}
                  inputProps={{ placeholder: "" }}
                />

                <div className="d-flex mt-2">
                  {serialNumbers.length !== 0 && (
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={() => setSerialNumbers([])}
                    >
                      Clear Serial Numbers
                    </Button>
                  )}
                  <small className="text-muted ml-auto">
                    Count: {serialNumbers.length}
                  </small>
                </div>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="lg" variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            size="lg"
            variant="primary"
            onClick={handleSubmit}
            disabled={
              includeSerialNumbers
                ? !stock || serialNumbers.length !== +stock
                : !stock
            }
          >
            Add Stock
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  updateStock: data => dispatch(updateStock(data)),
  updateReplacementStock: data => dispatch(updateReplacementStock(data)),
})

export default connect(undefined, mapDispatchToProps)(AddStock)
