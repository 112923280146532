import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { Modal, Button, Form } from "react-bootstrap"
import { FaWindowClose, FaSearch } from "react-icons/fa"
import { setReplacementProductSerialNumbers } from "../../state/actions/issueReplacement"
import styles from "../../styles/components/invoices/serialNumberModal.module.scss"

const ReplacementSerialNumberModal = ({
  id,
  product,
  setReplacementProductSerialNumbers,
  serialNumbersInIR,
  mode = "search",
  productSerials,
}) => {
  const [show, setShow] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    if (searchTerm.length > 14) {
      const matchingSerial = product?.serialNumbers?.filter(
        serialNumber =>
          serialNumber.main?.toLowerCase() === searchTerm.toLowerCase() ||
          serialNumber.complimentary?.toLowerCase() === searchTerm.toLowerCase()
      )

      const matchingPalletSerials = product?.serialNumbers?.filter(
        serialNumber =>
          serialNumber.palletNumber
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase())
      )

      if (matchingSerial?.length > 0) {
        handlePalletNumberChange(matchingSerial)
      }

      if (matchingPalletSerials?.length > 0) {
        handlePalletNumberChange(matchingPalletSerials)
      }

      setSearchTerm("")
    }
  }, [searchTerm, product?.serialNumbers])

  const handlePalletNumberChange = palletSerials => {
    const newSerialNumbersReplacement = [...serialNumbersInIR]
    palletSerials.forEach(serial => {
      const index = newSerialNumbersReplacement.findIndex(
        sn => sn.main === serial.main
      )
      if (index === -1) {
        newSerialNumbersReplacement.push(serial)
      } else {
        newSerialNumbersReplacement.splice(index, 1)
      }
    })

    setReplacementProductSerialNumbers(id, newSerialNumbersReplacement)
  }

  const filteredSerialNumbers = useMemo(() => {
    return product?.serialNumbers
      ?.filter(serialNumber => {
        const isSerialNumberSold = product.serialNumbersSold?.some(
          soldSerial => soldSerial.main === serialNumber.main
        )
        return !isSerialNumberSold
      })
      ?.filter(serialNumber => {
        const search = searchTerm?.split(";")[0]?.toLowerCase()
        return (
          serialNumber.main?.toLowerCase().includes(search) ||
          serialNumber.palletNumber?.toLowerCase().includes(search) ||
          serialNumber.complimentary?.toLowerCase().includes(search)
        )
      })
  }, [product?.serialNumbers, product?.serialNumbersSold, searchTerm])

  const serialNumbersToDisplay =
    mode === "search" ? filteredSerialNumbers : productSerials

  return (
    <>
      <Button onClick={() => setShow(true)} variant="secondary">
        {mode === "search" ? "Add" : "Show Serial Numbers"}
      </Button>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title>
            {mode === "search" ? "Add Serial Numbers" : "Serial Numbers"}
          </Modal.Title>
          <Button variant="danger" onClick={() => setShow(false)}>
            <FaWindowClose />
          </Button>
        </Modal.Header>
        <Modal.Body className="h-100 overflow-auto">
          {mode === "search" && (
            <div className={styles.search}>
              <input
                type="text"
                value={searchTerm}
                name="search"
                placeholder="Search"
                className={styles.searchInput}
                onChange={e => setSearchTerm(e.target.value)}
                autoComplete="off"
              />
              <FaSearch className={styles.searchIcon} />
            </div>
          )}

          {serialNumbersToDisplay?.length ? (
            <div>
              {serialNumbersToDisplay.map((serialNumber, idx) => (
                <Form.Group key={idx}>
                  {mode === "search" ? (
                    <Form.Check
                      className={styles.serialNumber}
                      type="checkbox"
                      label={`${serialNumber.main} ${
                        serialNumber.complimentary || ""
                      } ${serialNumber.palletNumber || ""}`}
                      checked={serialNumbersInIR.includes(serialNumber)}
                      onChange={() => handlePalletNumberChange([serialNumber])}
                    />
                  ) : (
                    <div className={styles.serialNumber}>
                      {`${serialNumber.main} ${
                        serialNumber.complimentary || ""
                      } ${serialNumber.palletNumber || ""}`}
                    </div>
                  )}
                </Form.Group>
              ))}
            </div>
          ) : (
            <div>No serial numbers available for this product</div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = (state, props) => {
  return {
    product: state.issueReplacement.replacementProducts.find(
      prod => prod.id === props.id
    ),
    serialNumbersInIR: state.issueReplacement.replacementProducts.find(
      prod => prod.id === props.id
    )?.serialNumbersInIR,
  }
}

const mapDispatchToProps = dispatch => ({
  setReplacementProductSerialNumbers: (id, serialNumbers) =>
    dispatch(setReplacementProductSerialNumbers(id, serialNumbers)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplacementSerialNumberModal)
