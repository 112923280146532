import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { setTroubleshootings } from "../../../state/actions/troubleShootings"
import { Row, Button, Table } from "react-bootstrap"
import { HiOutlineDocumentAdd } from "react-icons/hi"
import { navigate } from "gatsby"
import moment from "moment"
import { FaTools, FaArrowLeft, FaArrowRight, FaSearch } from "react-icons/fa"
import Loading from "../../loading"
import styles from "../../../styles/components/quotations/quotations.module.scss"

const Troubleshootings = ({
  troubleshootings,
  totalTroubleshootings,
  totalPages,
  loading,
  setTroubleshootings,
  customers,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [troubleshootingsPerPage, setTroubleshootingsPerPage] = useState(25)
  const [searchTerm, setSearchTerm] = useState("")
  const [value, setValue] = useState("")

  useEffect(() => {
    setTroubleshootings({
      skip: currentPage,
      limit: troubleshootingsPerPage,
      search: searchTerm,
    })
  }, [currentPage, troubleshootingsPerPage, searchTerm])

  const handleClickTroubleShoot = id => {
    navigate(`/app/troubleshooting/${id}`)
  }

  if (troubleshootings?.length === 0 && !searchTerm) {
    return <Loading />
  }

  const onSearch = searchValue => {
    setSearchTerm(searchValue)
    setValue(searchValue)
    setCurrentPage(1)
  }

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className="title">
          <FaTools />
          Troubleshootings
        </h1>
        <div className="action-buttons">
          <Button
            size="lg"
            variant="success"
            onClick={() => {
              navigate("/app/troubleshoot-form")
            }}
          >
            <HiOutlineDocumentAdd
              className="mb-1 mr-2"
              style={{ fontSize: "16px" }}
            />
            New Troubleshooting
          </Button>
        </div>
      </div>
      <div className="mt-5">
        {/* Search */}
        <div className={styles.search}>
          <input
            type="text"
            value={value}
            name="search"
            placeholder="Search"
            autoComplete="off"
            className={styles.searchInput}
            onChange={e => {
              setValue(e.target.value)
              setSearchTerm(e.target.value)
              setCurrentPage(1)
            }}
          />
          <FaSearch className={styles.searchIcon} />
        </div>
        <ul className={styles.dropdown}>
          {customers
            .filter(customer => {
              const searchName = value.toLowerCase()
              const customerName = customer.name.toLowerCase()

              return (
                searchName &&
                customerName.includes(searchName) &&
                customerName !== searchName
              )
            })
            .map(customer => (
              <div
                className={styles.dropdownRow}
                onClick={() => onSearch(customer?.name)}
              >
                {customer?.name}
              </div>
            ))}
        </ul>

        {troubleshootings && troubleshootings.length ? (
          <>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>TS No.</th>
                  <th>Installer Company Name</th>
                  <th>Invoice Number</th>
                  <th>Report Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {troubleshootings.map(troubleshoot => (
                  <tr
                    key={troubleshoot._id}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleClickTroubleShoot(troubleshoot._id)
                    }}
                  >
                    <td>{troubleshoot.trsfNumber}</td>
                    <td>{troubleshoot.installerCompanyName}</td>
                    <td>{troubleshoot.invoiceNumber}</td>
                    <td>
                      {moment(new Date(troubleshoot.reportDate)).format(
                        "DD-MM-YYYY"
                      )}
                    </td>
                    {troubleshoot?.status === "Issued" ? (
                      <td className="text-success">{troubleshoot.status}</td>
                    ) : troubleshoot?.status === "Cancelled" ? (
                      <td className="text-danger">{troubleshoot.status}</td>
                    ) : troubleshoot?.confirmReplacement === true ? (
                      <td className="text-primary">Replacement Confirmed</td>
                    ) : (
                      <td className="">{troubleshoot.status}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Pagination */}
            <Row className="d-flex justify-content-center align-items-center my-4">
              <div className="d-flex align-items-center mx-4 my-4 my-lg-0">
                <h3
                  className="small d-inline mr-2"
                  style={{
                    minWidth: "105px",
                  }}
                >
                  Items per page:
                </h3>
                <select
                  className="form-control form-control-lg"
                  value={troubleshootingsPerPage}
                  onChange={e => {
                    setCurrentPage(1)
                    setTroubleshootingsPerPage(Number(e.target.value))
                  }}
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <Row className="d-flex justify-content-center align-items-center mx-4">
                <Button
                  size="lg"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                  variant={currentPage === 1 ? "secondary" : "primary"}
                >
                  <FaArrowLeft />
                </Button>

                <span className="mx-3 small">
                  Page {currentPage} / {totalPages}
                </span>

                <Button
                  size="lg"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={
                    currentPage >=
                    totalTroubleshootings / troubleshootingsPerPage
                  }
                  variant="primary"
                >
                  <FaArrowRight />
                </Button>
              </Row>
            </Row>
          </>
        ) : (
          <p className="text-muted">No troubleshoots available!</p>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    troubleshootings: state.troubleshooting.troubleshootings || [],
    totalTroubleshootings: state.troubleshooting.totalTroubleshootings,
    totalPages: state.troubleshooting.totalPages,
    loading: state.troubleshooting.loading,
    customers: state.customer.customers,
  }
}

const mapDispatchToProps = {
  setTroubleshootings,
}

export default connect(mapStateToProps, mapDispatchToProps)(Troubleshootings)
