import React, { useState } from "react"
import { Form, Modal, Button, Table } from "react-bootstrap"
import { FaSearch, FaWindowClose } from "react-icons/fa"
import { connect } from "react-redux"
import { updateStock } from "../../state/actions/product"
import { updateReplacementStock } from "../../state/actions/replacementProduct"

import styles from "../../styles/components/inventory/removeStock.module.scss"

const RemoveStock = ({
  product,
  updateStock,
  updateReplacementStock,
  document,
}) => {
  const [show, setShow] = useState(false)
  const [stock, setStock] = useState(0)
  const [includeSerialNumbers, setIncludeSerialNumbers] = useState(false)
  const [serialNumbers, setSerialNumbers] = useState([])
  const [searchTerm, setSearchTerm] = useState("")

  const handleSerialNumberChange = serial => {
    if (serialNumbers.includes(serial)) {
      setSerialNumbers(serialNumbers.filter(sn => sn !== serial))
    } else {
      setSerialNumbers([...serialNumbers, serial])
    }
  }

  const handleSubmit = () => {
    const newStock = +product.stock - +stock

    const newSerialNumbers = product.serialNumbers.filter(
      sn => !serialNumbers.includes(sn)
    )

    {
      document === "product" &&
        updateStock({
          productSKU: product.sku,
          stock: newStock,
          serialNumbers: newSerialNumbers,
        })
    }

    {
      document === "replacementProduct" &&
        updateReplacementStock({
          productSKU: product.sku,
          stock: newStock,
          serialNumbers: newSerialNumbers,
        })
    }
  }

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        size="lg"
        variant="danger"
        style={{ marginLeft: "6px" }}
      >
        Remove Stock
      </Button>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title>Remove Stock</Modal.Title>
          <Button onClick={() => setShow(false)} variant="danger">
            <FaWindowClose />
          </Button>
        </Modal.Header>
        <Modal.Body>
          {product.productImages &&
            product.productImages.map((image, i) => (
              <div key={i} className="text-center">
                <img
                  className="m-4"
                  src={image.fields.file.url}
                  alt={image.fields.description}
                  style={{
                    maxHeight: "150px",
                  }}
                />
              </div>
            ))}
          <Table>
            <tbody>
              <tr>
                <td>SKU</td>
                <td>{product.sku}</td>
              </tr>
              <tr>
                <td>Title</td>
                <td>{product.productName}</td>
              </tr>
              <tr>
                <td>Stock in Hand</td>
                <td>{product.stock}</td>
              </tr>
            </tbody>
          </Table>
          <Form className="mt-3">
            <strong>Remove Stock</strong>
            <Form.Group controlId="stock">
              <Form.Label>Qty to remove:</Form.Label>
              <Form.Control
                size="lg"
                type="number"
                placeholder="Enter quantity to remove"
                value={stock}
                onChange={e => {
                  if (e.target.value > product.stock || e.target.value < 0) {
                    return
                  }
                  setStock(e.target.value)
                }}
              />
            </Form.Group>
            <Form.Group controlId="serialCheckbox" className="mt-2">
              <Form.Check
                className={styles.serialNumberCheckbox}
                type="checkbox"
                label="Include Serial Numbers"
                checked={includeSerialNumbers}
                onChange={() => setIncludeSerialNumbers(!includeSerialNumbers)}
              />
            </Form.Group>
            {includeSerialNumbers && (
              <div>
                {product.serialNumbers?.length ? (
                  <>
                    <div className={styles.serialNumberDiv}>
                      {/* Search Input */}
                      <div className={styles.search}>
                        <input
                          type="text"
                          value={searchTerm}
                          name="search"
                          placeholder="Search"
                          className={styles.searchInput}
                          onChange={e => setSearchTerm(e.target.value)}
                          autoComplete="off"
                        />
                        <FaSearch className={styles.searchIcon} />
                      </div>

                      {/* Serial Numbers */}
                      <div className={styles.serialNumbers}>
                        {product.serialNumbers
                          .filter(serialNumber => {
                            const search = searchTerm.toLowerCase()
                            const mainSerial = serialNumber.main.toLowerCase()
                            const complimentarySerial =
                              serialNumber.complimentary?.toLowerCase() || ""

                            if (
                              mainSerial.includes(search) ||
                              complimentarySerial.includes(search)
                            ) {
                              return serialNumber
                            } else return null
                          })
                          .sort((a, b) => {
                            if (
                              serialNumbers.includes(a) &&
                              !serialNumbers.includes(b)
                            ) {
                              return -1
                            } else if (
                              !serialNumbers.includes(a) &&
                              serialNumbers.includes(b)
                            ) {
                              return 1
                            } else {
                              return 0
                            }
                          })
                          .map(serialNumber => (
                            <Form.Group key={serialNumber.main}>
                              <Form.Check
                                className={styles.serialNumberCheckbox}
                                type="checkbox"
                                label={`${serialNumber.main} ${
                                  serialNumber.complimentary
                                    ? serialNumber.complimentary
                                    : " "
                                }`}
                                checked={serialNumbers.includes(serialNumber)}
                                onChange={() =>
                                  handleSerialNumberChange(serialNumber)
                                }
                              />
                            </Form.Group>
                          ))}
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      {serialNumbers.length !== 0 && (
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => setSerialNumbers([])}
                        >
                          Clear Serial Numbers
                        </Button>
                      )}
                      {serialNumbers.length !==
                        product.serialNumbers.length && (
                        <Button
                          className="ml-3"
                          size="sm"
                          variant="light"
                          onClick={() =>
                            setSerialNumbers([...product.serialNumbers])
                          }
                        >
                          Select All
                        </Button>
                      )}
                      <small className="text-muted ml-auto">
                        Count: {serialNumbers.length}
                      </small>
                    </div>
                  </>
                ) : (
                  <div>No serial numbers available for this product</div>
                )}
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="lg" variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            size="lg"
            variant="danger"
            onClick={handleSubmit}
            disabled={
              includeSerialNumbers
                ? !stock || serialNumbers.length !== +stock
                : !stock
            }
          >
            Remove Stock
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  updateStock: stock => dispatch(updateStock(stock)),
  updateReplacementStock: stock => dispatch(updateReplacementStock(stock)),
})

export default connect(undefined, mapDispatchToProps)(RemoveStock)
