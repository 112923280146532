import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Row, Button, Table } from "react-bootstrap"
import { setInvoices } from "../../../state/actions/invoices"
import moment from "moment"
import Loading from "../../loading"
import { FaSearch, FaArrowLeft, FaArrowRight } from "react-icons/fa"

import styles from "../../../styles/components/quotations/quotations.module.scss"

const SerialDatabase = ({
  invoices,
  invoiceTotal,
  customers,
  setInvoices,
  totalPages,
  products,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(25)
  const [searchTerm, setSearchTerm] = useState("")
  const [value, setValue] = useState("")

  useEffect(() => {
    setInvoices({
      skip: currentPage,
      limit: itemsPerPage,
      search: searchTerm,
    })
  }, [currentPage, itemsPerPage, searchTerm])

  if (!invoices && !searchTerm)
    return <Loading text="Loading Serial Database..." />

  const onSearch = searchName => {
    setSearchTerm(searchName)
    setValue(searchName)
    setCurrentPage(1)
  }

  // Flatten invoice data to show serial numbers in separate rows
  const flattenedData = invoices?.reduce((acc, invoice) => {
    // Skip if invoice is cancelled
    if (invoice.cancelled) return acc

    const serialNumbers = invoice.invoiceProducts
      // Filter for non-cancelled invoices, products with serial numbers, and SKUs starting with "INV"
      .filter(
        product =>
          product.serialNumbers?.length > 0 && product.sku.startsWith("INV")
      )
      .flatMap(product => {
        const productDetails = products.find(p => p.sku === product.sku)

        const warranty = product.newWarranty
        return product.serialNumbers.map(serial => ({
          invoiceNumber: invoice.invoiceNumber,
          customerName: customers.find(
            customer => customer.code === invoice.customerCode
          )?.name,
          productName: productDetails?.productName,
          warranty: warranty,
          serialNumber: serial.main,
          invoiceDate: moment(new Date(invoice.date)).format("DD-MM-YYYY"),
        }))
      })

    return [...acc, ...serialNumbers]
  }, [])

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className="title">Serial Database</h1>
      </div>
      <div className="mt-5">
        {/* Search */}
        <div className={styles.search}>
          <input
            type="text"
            value={value}
            name="search"
            placeholder="Search by Serial Number"
            autoComplete="off"
            className={styles.searchInput}
            onChange={e => {
              setValue(e.target.value)
              setSearchTerm(e.target.value)
              setCurrentPage(1)
            }}
          />
          <FaSearch className={styles.searchIcon} />
        </div>

        {flattenedData && flattenedData.length > 0 ? (
          <>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Customer Name</th>
                  <th>Serial Number</th>
                  <th>Product Name</th>
                  <th>Warranty</th>
                  <th>Invoice Date</th>
                </tr>
              </thead>
              <tbody>
                {flattenedData
                  ?.sort(
                    (a, b) =>
                      Number(b.invoiceNumber?.split("-")[1]) -
                      Number(a.invoiceNumber?.split("-")[1])
                  )
                  ?.map((item, index) => (
                    <tr
                      key={`${item.invoiceNumber}-${item.serialNumber}`}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{item.invoiceNumber}</td>
                      <td>{item.customerName}</td>
                      <td>{item.serialNumber}</td>
                      <td>{item.productName}</td>
                      <td>{item.warranty}</td>
                      <td>{item.invoiceDate}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {/* Pagination */}
            <Row className="d-flex justify-content-center align-items-center my-4">
              <div className="d-flex align-items-center mx-4 my-4 my-lg-0">
                <h3
                  className="small d-inline mr-2"
                  style={{
                    minWidth: "105px",
                  }}
                >
                  Items per page:
                </h3>
                <select
                  className="form-control form-control-lg"
                  value={itemsPerPage}
                  onChange={e => {
                    setCurrentPage(1)
                    setItemsPerPage(Number(e.target.value))
                  }}
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <Row className="d-flex justify-content-center align-items-center mx-4">
                <Button
                  size="lg"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                  variant={currentPage === 1 ? "secondary" : "primary"}
                >
                  <FaArrowLeft />
                </Button>

                <span className="mx-3 small">
                  Page {currentPage} / {totalPages}
                </span>

                <Button
                  size="lg"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage >= invoiceTotal / itemsPerPage}
                  variant="primary"
                >
                  <FaArrowRight />
                </Button>
              </Row>
            </Row>
          </>
        ) : (
          <p className="text-muted">No serial numbers available!</p>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    invoices: state.invoices.invoices,
    invoiceTotal: state.invoices.totalInvoices,
    totalPages: state.invoices.totalPages,
    customers: state.customer.customers,
    products: state.product.products,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setInvoices: data => dispatch(setInvoices(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SerialDatabase)
