import React, { useRef, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import Table from "react-bootstrap/Table"
import { Button } from "react-bootstrap"
import moment from "moment"
import { confirmAlert } from "react-confirm-alert"
import { navigate } from "gatsby-link"
import BackButton from "../../backButton"
import Loading from "../../loading"
import {
  cancelIssueReplacement,
  approveIssueReplacement,
} from "../../../state/actions/issueReplacement"

import {
  setTroubleshootings,
  updateTroubleshootingStatus,
} from "../../../state/actions/troubleShootings"
import IssueReplacementPreview from "./issueReplacementPreview"

import styles from "../../../styles/components/documentPreview.module.scss"

const ViewIssueReplacement = props => {
  const dispatch = useDispatch()

  const matchingTS = props.troubleshootings?.find(
    ts => ts.serialNumber === props.issueReplacement?.faultySerialNumber
  )?._id

  useEffect(() => {
    dispatch(setTroubleshootings())
  }, [dispatch])

  console.log(matchingTS)

  // Get replacement items with full product details
  const replacementItems = props.issueReplacement?.issueReplacementProducts?.map(
    item => {
      return {
        ...props.replacementProducts.find(product => product.sku === item.sku),
        sku: item.sku,
        price: item.price,
        quantity: item.quantity,
        discount: item.discount,
        discountType: item.discountType,
        newWarranty: item?.newWarranty,
        serialNumbers: item.serialNumbers,
      }
    }
  )

  const inputRef = useRef("")

  const cancelHandler = onClose => {
    props
      .cancelIssueReplacement(
        props.issueReplacement._id,
        inputRef.current.value
      )
      .then(success => {
        navigate("/app/issue-replacements")
      })
    onClose()
  }

  const confirmCancelIssueReplacement = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui border bg-white p-5 rounded">
            <h1>Cancel Issue Replacement</h1>
            <p>Are you sure you want to cancel this replacement?</p>
            <p>Reason for Cancel</p>
            <input ref={inputRef}></input>
            <br></br>
            <br></br>
            <button className="mr-2 border p-2" onClick={onClose}>
              No
            </button>
            <button
              className="border p-2"
              onClick={() => cancelHandler(onClose)}
            >
              Yes
            </button>
          </div>
        )
      },
    })
  }

  const confirmIssue = () => {
    confirmAlert({
      title: "Confirm Issue",
      message: "Are you sure you want to confirm this issue?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.approveIssueReplacement(props.issueReplacement._id)
            props.updateTroubleshootingStatus(matchingTS, "Issued")
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  const issueReplacementData = {
    documentNumber: props.issueReplacement.replacementNumber,
    warrantyClaimNumber: props.issueReplacement.warrantyClaimNumber,
    customerName: props.issueReplacement.customerName,
    replacementMethod: props.issueReplacement.replacementMethod,
    technicalTeamMember: props.issueReplacement.techTeam,
    faultyInverterReceived: props.issueReplacement.faultyInverterReceived,
    letterReceived: props.issueReplacement.letterReceived,
    faultySerialNumber: props.issueReplacement.faultySerialNumber,
    faultyDetails: props.issueReplacement.faultyDetails,
    replacementProducts: replacementItems,
    date: moment(new Date(props.issueReplacement.createdAt)).format(
      "DD-MM-YYYY"
    ),
  }

  const createdTime = props.issueReplacement.createdAt
  const formattedCreatedTime = new Date(createdTime).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  })

  return (
    <>
      <div className="mt-4">
        <h1>
          {props.issueReplacement.replacementNumber}{" "}
          {props.issueReplacement.issued && (
            <span className="text-success">(Issued)</span>
          )}
          {props.issueReplacement.cancelled && (
            <span className="text-danger">Cancelled</span>
          )}
        </h1>

        {props.issueReplacement?.createdAt && (
          <div>
            Created At - <span className="">{formattedCreatedTime}</span>
          </div>
        )}

        <BackButton to="/app/issue-replacements" />

        {/* Action Buttons */}
        <div className="action-buttons mt-4">
          {/* Issue Replacements */}
          {!props.issueReplacement.cancelled && !props.issueReplacement.issued && (
            <Button
              className="mr-4"
              variant="success"
              onClick={() => confirmIssue()}
            >
              Approve
            </Button>
          )}

          {!props.issueReplacement.cancelled && !props.issueReplacement.issued && (
            <Button
              variant="danger"
              onClick={() => confirmCancelIssueReplacement()}
            >
              Cancel
            </Button>
          )}

          {
            <div className="text-danger">
              {props.issueReplacement.cancelled &&
                props.issueReplacement.reasonForCancel &&
                `reason for cancel - ${props?.issueReplacement?.reasonForCancel}`}
            </div>
          }
        </div>
        <IssueReplacementPreview data={issueReplacementData} warranty="show" />
      </div>
    </>
  )
}

const mapStateToProps = (state, props) => {
  console.log(state)

  return {
    issueReplacement: state.issueReplacement.replacements.find(
      replacement => replacement._id === props.id
    ),
    replacementProducts: state.replacementProduct.replacementProducts,
    isLoading: state.issueReplacement.isLoading,
    canApproveReplacement: state.auth.auth.user.canApproveReplacement,
    canRejectReplacement: state.auth.auth.user.canRejectReplacement,
    troubleshootings: state.troubleshooting.troubleshootings,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    cancelIssueReplacement: (id, reason) =>
      dispatch(cancelIssueReplacement(id, reason)),
    approveIssueReplacement: id => dispatch(approveIssueReplacement(id)),
    updateTroubleshootingStatus: (id, status) =>
      dispatch(updateTroubleshootingStatus(id, status)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewIssueReplacement)
