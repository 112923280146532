import { toast } from "react-toastify"
import axios from "axios"
import _ from "lodash"
import { navigate } from "gatsby"

// Set replacement products
export const setIssueReplacementProducts = products => {
  return dispatch => {
    dispatch({
      type: "SET_ISSUE_REPLACEMENT_PRODUCTS",
      data: products,
    })
  }
}

// Save replacement to the database
export const saveReplacement = data => {
  const replacementData = {
    warrantyClaimNumber: data.warrantyClaimNumber,
    replacementMethod: data.replacementMethod,
    techTeam: data.techTeam,
    faultyInverterReceived: data.faultyInverterReceived,
    letterReceived: data.letterReceived,
    customerName: data.customerName,
    faultySerialNumber: data.faultySerialNumber,
    faultyDetails: data.faultyDetails,
    issueReplacementProducts: data.products.map(prod => {
      return {
        sku: prod.sku,
        price: prod.price,
        quantity: prod.quantity,
        discount: prod.discount,
        discountType: prod.discountType,
        newWarranty: prod.newWarranty,
        serialNumbers: prod.serialNumbersInIR,
      }
    }),
  }

  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      const data = await axios.post(
        `${process.env.GATSBY_API_CALL}/issue-replacements`,
        replacementData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch({
        type: "SAVE_REPLACEMENT",
        data: data.data.replacementNumber,
      })
      toast.success("Success: Issue Replacement saved!")
      navigate("/app/issue-replacements")
      return data.data.replacementNumber
    } catch (e) {
      console.log(e.response)
      toast.error(`Error: ${e.response?.data.message} `)
    }
  }
}

// Fetch all replacements
export const fetchReplacements = data => {
  return async (dispatch, getState) => {
    const skip = data?.skip || 1
    const limit = data?.limit || 25
    const search = data?.search || "" // Add search term

    dispatch({
      type: "SET_REPLACEMENT_LOADING",
      data: true,
    })

    const token = getState().auth.auth.token

    try {
      let searchParam = search

      if (search !== "" && !/^\d+$/.test(search)) {
        // Assume it's a customer name and try to find the customer codes
        const customers = getState().customer.customers

        // Find matching customers based on a case-insensitive substring match
        const matchingCustomers = customers.filter(customer =>
          customer.name
            .trim()
            .toLowerCase()
            .includes(search.trim().toLowerCase())
        )

        // Take the top result as the search parameter
        const topCustomer = matchingCustomers[0]

        // Use the customer code of the top result as the search term
        if (topCustomer) {
          searchParam = topCustomer.name
        }
      }

      const response = await axios.get(
        `${
          process.env.GATSBY_API_CALL
        }/issue-replacements?page=${skip}&limit=${limit}${
          searchParam ? `&search=${searchParam}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      dispatch({
        type: "FETCH_REPLACEMENTS",
        data: response.data.replacements,
      })

      dispatch({
        type: `SET_TOTAL_REPLACEMENTS`,
        data: response.data.totalReplacements,
      })

      dispatch({
        type: `SET_TOTAL_REPLACEMENTS_PAGES`,
        data: response.data.totalPages,
      })

      dispatch({
        type: `SET_REPLACEMENT_LOADING`,
        data: false,
      })
    } catch (e) {
      console.log(e.response)
      toast.error("Failed to fetch replacements.")
      dispatch({
        type: `SET_REPLACEMENT_LOADING`,
        data: false,
      })
    }
  }
}

// Fetch a single replacement by ID
export const fetchReplacement = id => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      const response = await axios.get(
        `${process.env.GATSBY_API_CALL}/issue-replacement/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch({
        type: "FETCH_REPLACEMENT",
        data: response.data,
      })
    } catch (e) {
      console.log(e.response)
      toast.error("Failed to fetch replacement.")
    }
  }
}

// Cancel an issue replacement
export const cancelIssueReplacement = id => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/issue-replacement/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch({
        type: "CANCEL_REPLACEMENT",
        data: id,
      })
      toast.success("Replacement cancelled successfully.")
    } catch (e) {
      console.log(e.response)
      toast.error("Failed to cancel replacement.")
    }
  }
}

// Approve an issue replacement
export const approveIssueReplacement = id => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      const response = await axios.put(
        `${process.env.GATSBY_API_CALL}/issue-replacement/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      dispatch({
        type: "APPROVE_REPLACEMENT",
        data: response.data,
      })

      toast.success("Issue Replacement approved successfully.")
    } catch (e) {
      console.error(e.response)
      toast.error("Failed to approve issue replacement.")
    }
  }
}

// Clear replacement form
export const clearReplacement = () => {
  return dispatch => {
    dispatch({
      type: "CLEAR_REPLACEMENT",
    })
  }
}
// Add a replacement product
export const addReplacementProduct = product => {
  return (dispatch, getState) => {
    const replacementProductsFromState = getState().issueReplacement
      .replacementProducts

    const itemExists =
      replacementProductsFromState &&
      replacementProductsFromState.find(prod => {
        return prod.id === product
      })
    if (itemExists) {
      toast.error(`Error. Item has already been added.`)
      return null
    }

    const productData = getState().replacementProduct.replacementProducts.find(
      prod => {
        if (prod.id === product) {
          return prod
        } else {
          return null
        }
      }
    )

    if (productData.stock < 1) {
      toast.error("Product is out of stock")
      return
    }

    const newProducts = _.concat(
      getState().issueReplacement.replacementProducts || [],
      {
        ...productData,
        quantity: 1,
        discount: 0,
        discountType: "%",
        newWarranty: "10 years",
        serialNumbersInIR: [],
        includeSerialNumbers: false,
      }
    )

    dispatch({
      type: "ADD_REPLACEMENT_PRODUCT",
      data: newProducts,
    })
  }
}

// Delete a replacement product
export const deleteReplacementProduct = productId => {
  return (dispatch, getState) => {
    const newProducts = [...getState().issueReplacement.replacementProducts]
    const productIndex = _.findIndex(newProducts, product => {
      return product.id === productId
    })

    if (productIndex > -1) {
      newProducts.splice(productIndex, 1)
    }

    dispatch({
      type: "SET_ISSUE_REPLACEMENT_PRODUCTS",
      data: newProducts,
    })
  }
}

// Set quantity for a replacement product
export const setReplacementProductQuantity = (productId, quantity) => {
  return (dispatch, getState) => {
    const newProducts = [...getState().issueReplacement.replacementProducts]
    _.forEach(newProducts, product => {
      if (product.id === productId) {
        if (quantity > product.stock) {
          toast.error(`Only ${product.stock} items available in stock.`)
          return
        }

        if (quantity === "") {
          product.quantity = 0
        } else {
          product.quantity = quantity
        }
      }
    })
    dispatch({
      type: "SET_ISSUE_REPLACEMENT_PRODUCTS",
      data: newProducts,
    })
  }
}

// Set discount for a replacement product
export const setReplacementProductDiscount = (productId, discount) => {
  return (dispatch, getState) => {
    const newProducts = [
      ...getState().issueReplacement.replacementProducts,
    ].map(prod => {
      if (prod.id === productId) {
        if (discount) {
          return { ...prod, discount }
        } else {
          return { ...prod, discount: 0 }
        }
      } else {
        return prod
      }
    })
    dispatch({
      type: "SET_ISSUE_REPLACEMENT_PRODUCTS",
      data: newProducts,
    })
  }
}

// Set discount type for a replacement product
export const setReplacementProductDiscountType = (productId, discountType) => {
  return (dispatch, getState) => {
    const newProducts = [
      ...getState().issueReplacement.replacementProducts,
    ].map(prod => {
      if (prod.id === productId) {
        return { ...prod, discountType }
      } else {
        return prod
      }
    })
    dispatch({
      type: "SET_ISSUE_REPLACEMENT_PRODUCTS",
      data: newProducts,
    })
  }
}

// Set include serial numbers for a replacement product
export const setReplacementProductIncludeSerialNumbers = (
  productId,
  includeSerialNumbers
) => {
  return (dispatch, getState) => {
    const newProducts = [
      ...getState().issueReplacement.replacementProducts,
    ].map(prod => {
      if (prod.id === productId) {
        return {
          ...prod,
          includeSerialNumbers,
          serialNumbersInIR: [],
        }
      } else {
        return prod
      }
    })
    dispatch({
      type: "SET_ISSUE_REPLACEMENT_PRODUCTS",
      data: newProducts,
    })
  }
}

// Set serial numbers for a replacement product
export const setReplacementProductSerialNumbers = (
  productId,
  serialNumbersInIR
) => {
  return (dispatch, getState) => {
    const newProducts = getState().issueReplacement.replacementProducts.map(
      product => {
        if (product.id === productId) {
          return {
            ...product,
            serialNumbersInIR: serialNumbersInIR,
          }
        } else {
          return product
        }
      }
    )

    dispatch({
      type: "SET_ISSUE_REPLACEMENT_PRODUCTS",
      data: newProducts,
    })
  }
}

// Set product warranty Time
export const setReplacementProductWarrantyTime = (productId, newWarranty) => {
  return (dispatch, getState) => {
    const newProducts = getState().issueReplacement.replacementProducts.map(
      product => {
        if (product.id === productId) {
          return { ...product, newWarranty }
        } else {
          return product
        }
      }
    )

    dispatch({
      type: "SET_ISSUE_REPLACEMENT_PRODUCTS",
      data: newProducts,
    })
  }
}
