import React, { useState, useEffect } from "react"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer"
import { Button } from "react-bootstrap"
import wr_1 from "../../../images/warrentyReplacementForm/wr_1.png"
import wr_2 from "../../../images/warrentyReplacementForm/wr_2.png"
import check from "../../../images/warrentyReplacementForm/check-black.png"

import { FaFilePdf } from "react-icons/fa"

const Checkmark = ({}) => (
  <View>
    <Image src={check} style={styles.checkmark} />
  </View>
)

const WarrantyReplacementPDF = ({ data }) => {
  const [isClient, setClient] = useState(false)
  const [showDownloadLink, setShowDownloadLink] = useState(false)

  useEffect(() => {
    setClient(true)
    setShowDownloadLink(true)
  }, [])

  if (!isClient) return null

  const MyDocument = ({ data }) => (
    <Document>
      <Page size={{ width: 1190, height: 920 }} style={styles.page}>
        <View style={styles.view_1}>
          <Image src={wr_1} style={styles.image} />

          {/* Date and Code */}
          <Text style={[styles.text, styles.date]}>
            {new Date().toLocaleDateString()}
          </Text>
          {/* <Text style={[styles.text, styles.codeId]}>
            {data.warrantyNumber || "00"}
          </Text> */}

          {/* Plant Information */}
          <Text style={[styles.text, styles.plantName]}>
            {data.installerCompanyName}
          </Text>
          <Text style={[styles.text, styles.country]}>Sri Lanka</Text>

          <Text style={[styles.text, styles.city]}>{data.city}</Text>
          <Text style={[styles.text, styles.proofOfPurchase]}>
            {`Sold by Gunda power – Invoice No – ${data.invoiceNumber}`}
          </Text>

          {/* Faulty Inverter Details */}
          <Text style={[styles.text, styles.inverterModel]}>
            {data.modelType}
          </Text>
          <Text style={[styles.text, styles.serialNumber]}>
            {data.serialNumber}
          </Text>
          <Text style={[styles.text, styles.installDate]}>
            {data.installationDate?.split("T")[0]}
          </Text>

          {data.dcIsoYes && (
            <View style={[styles.text, styles.dcIsoYes]}>
              <Checkmark />
            </View>
          )}

          {data.dcIsoNo && (
            <View style={[styles.text, styles.dcIsoNo]}>
              <Checkmark />
            </View>
          )}

          <Text style={[styles.text, styles.softwareVersion]}>
            {data.softwareVersion}
          </Text>
          <Text style={[styles.text, styles.totalInverters]}>{"01"}</Text>

          {/* Failure Description */}
          <Text style={[styles.text, styles.errorCode]}>{data.errorCode}</Text>
          <Text style={[styles.text, styles.errorMessage]}>
            {data.errorMessage}
          </Text>

          {/* LED Status */}
          {data.ledLightStatusPower && (
            <View style={[styles.text, styles.ledPowerOn]}>
              <Checkmark />
            </View>
          )}
          {!data.ledLightStatusPower && (
            <View style={[styles.text, styles.ledPowerOff]}>
              <Checkmark />
            </View>
          )}

          {data.ledLightStatusOperation && (
            <View style={[styles.text, styles.ledOperationOn]}>
              <Checkmark />
            </View>
          )}

          {!data.ledLightStatusOperation && (
            <View style={[styles.text, styles.ledOperationOff]}>
              <Checkmark />
            </View>
          )}

          {data.ledLightStatusAlarm && (
            <View style={[styles.text, styles.ledAlarmOn]}>
              <Checkmark />
            </View>
          )}

          {!data.ledLightStatusAlarm && (
            <View style={[styles.text, styles.ledAlarmOff]}>
              <Checkmark />
            </View>
          )}

          {/* Display Status */}
          {data.displayStatusOn && (
            <View style={[styles.text, styles.displayStatusOn]}>
              <Checkmark />
            </View>
          )}

          {data.displayStatusOff && (
            <View style={[styles.text, styles.displayStatusOff]}>
              <Checkmark />
            </View>
          )}

          {/* AC Voltage */}
          <Text style={[styles.text, styles.acVoltageL1]}>
            {data.acVoltageL1}
          </Text>
          <Text style={[styles.text, styles.acVoltageL2]}>
            {data.acVoltageL2}
          </Text>
          <Text style={[styles.text, styles.acVoltageL3]}>
            {data.acVoltageL3}
          </Text>
          <Text style={[styles.text, styles.acVoltageNPE]}>
            {data.acVoltageNPE}
          </Text>

          {/* PV Voltage */}
          <Text style={[styles.text, styles.pvVoltageDc1]}>
            {data.pvVoltageDc1}
          </Text>
          <Text style={[styles.text, styles.pvVoltageDc2]}>
            {data.pvVoltageDc2}
          </Text>
          <Text style={[styles.text, styles.pvVoltageDc3]}>
            {data.pvVoltageDc3}
          </Text>
          <Text style={[styles.text, styles.pvVoltageDc4]}>
            {data.pvVoltageDc4}
          </Text>

          {/* Grid Connection Checkmarks */}
          {data.gridConnectionSingle && (
            <View style={[styles.text, styles.gridSingle]}>
              <Checkmark />
            </View>
          )}
          {data.gridConnectionThree && (
            <View style={[styles.text, styles.gridThree]}>
              <Checkmark />
            </View>
          )}
          {data.gridConnectionTT && (
            <View style={[styles.text, styles.gridTT]}>
              <Checkmark />
            </View>
          )}
          {data.gridConnectionTN && (
            <View style={[styles.text, styles.gridTN]}>
              <Checkmark />
            </View>
          )}
          {data.gridConnectionIT && (
            <View style={[styles.text, styles.gridIT]}>
              <Checkmark />
            </View>
          )}
        </View>
      </Page>

      {/* page 2 */}
      <Page size={{ width: 1190, height: 920 }} style={styles.page}>
        <View style={styles.view_1}>
          <Image src={wr_2} style={styles.image} />

          {/* INSTALLER COMMENTS */}
          <Text style={[styles.text, styles.comment1]}>{data.comment1}</Text>
          <Text style={[styles.text, styles.comment2]}>{data.comment2}</Text>
          <Text style={[styles.text, styles.comment3]}>{data.comment3}</Text>
          <Text style={[styles.text, styles.comment4]}>{data.comment4}</Text>
          <Text style={[styles.text, styles.comment5]}>{data.comment5}</Text>

          {/* USER DETAILS */}
          <Text style={[styles.text, styles.userName]}>{data.userName}</Text>
          <Text style={[styles.text, styles.userEmail]}>{data.userEmail}</Text>
          <Text style={[styles.text, styles.userPhone]}>{data.userPhone}</Text>
        </View>
      </Page>
    </Document>
  )

  return (
    <div>
      {isClient && showDownloadLink && (
        <PDFDownloadLink
          document={<MyDocument data={data} />}
          fileName={`warranty-replacement-${data.installerCompanyName}.pdf`}
        >
          {({ loading }) => (
            <Button variant="primary" disabled={loading}>
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" />
                  Preparing Download...
                </>
              ) : (
                <>
                  <FaFilePdf className="mr-2" />
                  Download Warranty Form
                </>
              )}
            </Button>
          )}
        </PDFDownloadLink>
      )}
    </div>
  )
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    width: "100%",
    orientation: "landscape",
  },

  grid: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 2,
  },

  gridLine: {
    position: "absolute",
    backgroundColor: "#000000",
    opacity: 0.5,
  },

  view_1: {
    position: "relative",
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
  },
  image: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: 0,
  },
  text: {
    position: "absolute",
    fontSize: 16,
    color: "#000000",
  },
  // Header
  date: {
    top: 180,
    right: 80,
  },
  codeId: {
    top: 40,
    right: 150,
  },

  // Plant Information
  plantName: {
    top: 303,
    left: 285,
  },
  country: {
    top: 303,
    left: 630,
  },
  city: {
    top: 303,
    left: 905,
  },
  proofOfPurchase: {
    top: 330,
    left: 330,
  },
  // Inverter Details
  inverterModel: {
    top: 403,
    left: 275,
  },
  serialNumber: {
    top: 403,
    left: 580,
  },
  installDate: {
    top: 403,
    left: 940,
  },

  dcIsoYes: {
    top: 432,
    left: 280,
  },
  dcIsoNo: {
    top: 432,
    left: 390,
  },

  softwareVersion: {
    top: 428,
    left: 580,
  },
  totalInverters: {
    top: 428,
    left: 970,
  },
  // Error Information
  errorCode: {
    top: 505,
    left: 255,
  },
  errorMessage: {
    top: 505,
    left: 655,
  },
  // LED Status
  ledPowerOn: {
    top: 527,
    left: 378,
    position: "absolute",
  },
  ledPowerOff: {
    top: 527,
    left: 475,
    position: "absolute",
  },
  ledOperationOn: {
    top: 551,
    left: 378,
    position: "absolute",
  },
  ledOperationOff: {
    top: 551,
    left: 475,
    position: "absolute",
  },
  ledAlarmOn: {
    top: 568,
    left: 378,
    position: "absolute",
  },
  ledAlarmOff: {
    top: 568,
    left: 475,
    position: "absolute",
  },

  // Display Status
  displayStatusOn: {
    top: 552,
    left: 705,
    position: "absolute",
  },
  displayStatusOff: {
    top: 552,
    left: 800,
    position: "absolute",
  },

  // AC Voltage
  acVoltageL1: {
    top: 621,
    left: 295,
    fontSize: 12,
  },
  acVoltageL2: {
    top: 621,
    left: 410,
    fontSize: 12,
  },
  acVoltageL3: {
    top: 657,
    left: 295,
    fontSize: 12,
  },
  acVoltageNPE: {
    top: 657,
    left: 410,
    fontSize: 12,
  },

  // Grid Connection
  gridSingle: {
    top: 621,
    left: 758,
  },
  gridThree: {
    top: 621,
    left: 895,
  },
  gridTT: {
    top: 661,
    left: 750,
  },
  gridTN: {
    top: 661,
    left: 895,
  },
  gridIT: {
    top: 661,
    left: 1040,
  },

  // PV Voltage
  pvVoltageDc1: {
    top: 723,
    left: 290,
    fontSize: 12,
  },
  pvVoltageDc2: {
    top: 723,
    left: 410,
    fontSize: 12,
  },
  pvVoltageDc3: {
    top: 763,
    left: 290,
    fontSize: 12,
  },
  pvVoltageDc4: {
    top: 763,
    left: 410,
    fontSize: 12,
  },

  comment1: {
    top: 118,
    left: 254,
  },
  comment2: {
    top: 140,
    left: 254,
  },
  comment3: {
    top: 163,
    left: 254,
  },
  comment4: {
    top: 185,
    left: 254,
  },
  comment5: {
    top: 208,
    left: 254,
  },

  userName: {
    top: 318,
    left: 285,
  },

  userEmail: {
    top: 340,
    left: 285,
  },

  userPhone: {
    top: 365,
    left: 285,
  },

  checkmark: {
    width: 15,
    height: 15,
  },
})

export default React.memo(WarrantyReplacementPDF)
