import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import {
  setReplacementProducts,
  saveReplacement,
  clearReplacement,
} from "../../../state/actions/issueReplacement"
import { Form, Row, Col, Button } from "react-bootstrap"
import Select from "react-select"
import ProductTable from "../../productTable"
import ProductModal from "../../productModal"
import BackButton from "../../backButton"

import styles from "../../../styles/components/technicalTeam/issueReplacementForm.module.scss"

const IssueReplacementForm = ({
  currentTroubleshooting,
  saveReplacement,
  replacementProducts,
}) => {
  const [formData, setFormData] = useState({
    warrantyClaimNumber:
      `WR-${currentTroubleshooting?.trsfNumber?.split("-")[1]}` || "",
    replacementMethod: "",
    techTeam: "",
    faultyInverterReceived: false,
    letterReceived: false,
    customerName: currentTroubleshooting?.installerCompanyName || "",
    faultySerialNumber: currentTroubleshooting?.serialNumber || "",
    faultyDetails: currentTroubleshooting?.errorMessage || "",
    products: replacementProducts,
  })

  const handleChange = e => {
    const { name, value, type, checked } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData(prev => ({
      ...prev,
      [name]: selectedOption.value,
    }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const replacementData = {
      ...formData,
      products: replacementProducts,
    }

    try {
      saveReplacement(replacementData)
    } catch (error) {
      console.error("Error saving replacement:", error)
    }
  }

  const replacementOptions = [
    { value: "cash", label: "Cash Replacement" },
    { value: "warranty", label: "Warranty Replacement" },
  ]

  const techTeamOptions = [
    { value: "thilina", label: "Thilina" },
    { value: "chandana", label: "Chandana" },
    { value: "chaminda", label: "Chaminda" },
    { value: "edison", label: "Edison" },
  ]

  return (
    <div className={styles.wrapper}>
      <h1 className="title">Issue Replacement</h1>
      <BackButton to={`/app/troubleshooting/${currentTroubleshooting?._id}`} />

      <Form onSubmit={handleSubmit} className="my-5">
        <Row>
          {/* Left Column */}
          <Col md={6} className="border-end">
            <Form.Group className="mb-4">
              <Form.Label>Replacement Method</Form.Label>
              <Select
                options={replacementOptions}
                placeholder="Select Replacement Type"
                onChange={selectedOption =>
                  handleSelectChange(selectedOption, {
                    name: "replacementMethod",
                  })
                }
                required
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Technical Team Member Name</Form.Label>
              <Select
                options={techTeamOptions}
                placeholder="Select Tech Team"
                onChange={selectedOption =>
                  handleSelectChange(selectedOption, { name: "techTeam" })
                }
                required
              />
            </Form.Group>

            <Form.Group className="mb-4 d-flex justify-content-between">
              <Form.Check
                name="faultyInverterReceived"
                type="checkbox"
                label="Faulty Inverter Received"
                inline
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-4 d-flex justify-content-between">
              <Form.Check
                name="letterReceived"
                type="checkbox"
                label="Letter Received"
                inline
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          {/* Right Column */}
          <Col md={6}>
            <Form.Group className="mb-4">
              <Form.Label>Warranty Claim Number</Form.Label>
              <Form.Control
                size="lg"
                name="warrantyClaimNumber"
                type="text"
                value={formData.warrantyClaimNumber}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Customer Name</Form.Label>
              <Form.Control
                size="lg"
                name="customerName"
                type="text"
                value={formData.customerName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Faulty Serial Number</Form.Label>
              <Form.Control
                size="lg"
                name="faultySerialNumber"
                type="text"
                value={formData.faultySerialNumber}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-4">
          <Form.Label>Faulty Details</Form.Label>
          <Form.Control
            size="lg"
            name="faultyDetails"
            as="textarea"
            rows={3}
            placeholder="Enter faulty details"
            onChange={handleChange}
          />
        </Form.Group>

        {/* Product Table & Product Select */}

        <div className={styles.productTable}>
          <h2 className={styles.productTableHeading}>Products</h2>
          <ProductTable document="replacement" />
          <ProductModal document="replacement" />
        </div>

        {/* Submit Button */}

        <Row className="mt-5">
          <Button className={styles.btn} variant="primary" type="submit">
            Submit
          </Button>
        </Row>
      </Form>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    currentTroubleshooting: state.troubleshooting.currentTroubleshooting,
    replacementProducts: state.issueReplacement.replacementProducts,
  }
}

const mapDispatchToProps = {
  setReplacementProducts,
  saveReplacement,
  clearReplacement,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueReplacementForm)
