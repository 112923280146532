import React, { useRef } from "react"
import moment from "moment"
import { connect } from "react-redux"
import { Button } from "react-bootstrap"
import { confirmAlert } from "react-confirm-alert"
import { navigate } from "gatsby-link"
import DocumentPreview from "../documentPreview"
import BackButton from "../backButton"
import PDFGenerator from "../pdfGenerator"
import {
  setCreditNoteDate,
  setCreditNoteCustomer,
  setCreditNoteProducts,
  cancelCreditNote,
  setCreditNoteId,
  reasonForCancle,
} from "../../state/actions/creditNote"
import { setCustomer, setProducts } from "../../state/actions/invoice"
import { setProductsFromDatabase } from "../../state/actions/product"
import { toast } from "react-toastify"
import Loading from "../loading"

const ViewCreditNote = props => {
  const creditNoteItems = props.creditNote.creditNoteProducts?.map(item => {
    return {
      ...props.products.find(product => product.sku === item.sku),
      sku: item.sku,
      price: item.price,
      discount: item.discount,
      discountType: item.discountType,
      quantity: item.quantity,
      serialNumbersInCreditNote: item.serialNumbers,
    }
  })

  const inputRef = useRef("")

  const cancelCreditNoteHandler = () => {
    props
      .cancelCreditNote(
        props.creditNote._id,
        props.creditNote.creditNoteProducts
      )
      .then(success => {
        if (success) {
          props.setProductsFromDatabase()
          navigate("/app/credit-notes")
        } else {
          toast.error("Failed to cancel the credit note.")
        }
      })
  }

  const confirmCancelCreditNote = () => {
    confirmAlert({
      title: "Confirm to cancel",
      message: "Are you sure you want to cancel this credit note?",
      buttons: [
        {
          label: "Yes",
          onClick: () => cancelCreditNoteHandler(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    })
  }
  const creditNoteData = {
    documentNumber: props.creditNote.creditNoteNumber,
    invoiceNumber: props.creditNote.invoiceNumber,
    senderAddress: props.senderAddress,
    senderCity: props.senderCity,
    senderTel: props.senderTel,
    customer: props.customer.find(
      customer => customer.code === props.creditNote.customerCode
    ),
    user: props.creditNote.user,
    date: moment(new Date(props.creditNote.date)).format("DD-MM-YYYY"),
    creditNoteProducts: creditNoteItems,
    status: props.creditNote.cancelled,
  }

  return (
    <>
      <div className="mt-4">
        <h1>
          {props.creditNote.creditNoteNumber}{" "}
          {props.creditNote.cancelled && (
            <span className="text-danger">(Cancelled)</span>
          )}
        </h1>

        <BackButton to="/app/credit-notes" />
        {/* Action Buttons */}
        <div className="action-buttons mt-4">
          {!props.creditNote.cancelled && props.canCancelCreditNote && (
            <Button
              variant="danger"
              className="mr-4"
              onClick={() => confirmCancelCreditNote()}
            >
              Cancel Credit Note
            </Button>
          )}
          <div className="text-danger mt-1">
            {props.creditNote?.isCanceled &&
              props?.creditNote?.reasonForCancel &&
              `reason for cancel - ${props?.creditNote?.reasonForCancel}`}
          </div>
        </div>
        {document && (
          <>
            <DocumentPreview
              data={creditNoteData}
              warranty="show"
              document="creditNote"
            />

            {!props.isLoading &&
            !props.creditNote.cancelled &&
            creditNoteData ? (
              <PDFGenerator data={creditNoteData} document="creditNote" />
            ) : (
              !props.creditNote.cancelled && (
                <Loading height="" text="Loading PDF Download" />
              )
            )}
          </>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state, props) => {
  return {
    creditNote: state.creditNotes.creditNotes.find(
      creditNote => creditNote._id === props.id
    ),

    products: state.product.products,
    customer: state.customer.customers,
    creditNoteCustomer: state.invoice.customer,
    senderAddress: state.invoice.senderAddress,
    senderCity: state.invoice.senderCity,
    senderTel: state.invoice.senderTel,
    admin: state.auth.auth.user.admin,
    isLoading: state.invoice.isLoading,
    canCancelCreditNote: state?.auth?.auth?.user?.canCancelCreditNote,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setProductsFromDatabase: () => dispatch(setProductsFromDatabase()),
    setCreditNoteDate: date => dispatch(setCreditNoteDate(date)),
    setCreditNoteCustomer: data => dispatch(setCreditNoteCustomer(data)),
    setCreditNoteProducts: products =>
      dispatch(setCreditNoteProducts(products)),
    setCreditNoteId: id => dispatch(setCreditNoteId(id)),

    // Create Invoice Actions
    setDate: data => dispatch({ type: `SET_DATE`, data }),

    setQuotationNumber: data =>
      dispatch({ type: `SET_INVOICE_QUOTATION_NUMBER`, data }),

    // Cancel Credit Note
    cancelCreditNote: (id, products) =>
      dispatch(cancelCreditNote(id, products)),

    reasonForCancle: (id, reason) => dispatch(reasonForCancle(id, reason)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCreditNote)
