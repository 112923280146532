import React from "react"
import { Line } from "react-chartjs-2"
import moment from "moment"

const InvoiceChart = ({ data }) => {
  // Group the invoices by date and calculate the total for each day
  const dailyTotals = {}
  data.forEach(invoice => {
    const date = moment(invoice.date).format("MMM Do")
    if (!dailyTotals[date]) {
      dailyTotals[date] = 0
    }
    dailyTotals[date] += invoice.invoiceTotal
  })

  const monthlyTotals = {}
  data.forEach(invoice => {
    const month = moment(invoice.date).format("MMM YYYY") // Group by Month & Year
    if (!monthlyTotals[month]) {
      monthlyTotals[month] = 0
    }
    monthlyTotals[month] += invoice.invoiceTotal
  })

  // Extract the dates and total amounts from the daily totals object
  const dates = Object.keys(dailyTotals)
  const totals = Object.values(dailyTotals)

  // Get the start and end dates from the first and last invoice in the data arrays
  const startDate = moment(data[0].date)?.format("MMM Do YYYY")
  const endDate = moment(data[data.length - 1]?.date).format("MMM Do YYYY")

  // Calculate some additional information to display
  const totalRevenue = totals.reduce((a, b) => a + b, 0)
  const averageDailyRevenue = totalRevenue / dates.length
  const maxRevenueDay = dates.reduce(
    (max, date) => (dailyTotals[date] > dailyTotals[max] ? date : max),
    dates[0]
  )
  const maxRevenueValue = dailyTotals[maxRevenueDay]

  const totalMonths = Object.keys(monthlyTotals).length
  const totalMonthlyRevenue = Object.values(monthlyTotals).reduce(
    (a, b) => a + b,
    0
  )
  const averageMonthlyRevenue = totalMonthlyRevenue / totalMonths

  const chartData = {
    labels: dates,
    datasets: [
      {
        label: "Total Revenue",
        data: totals,
        fill: false,
        borderColor: "#36A2EB",
        tension: 0.1,
      },
    ],
  }

  const options = {
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "day",
            displayFormats: {
              day: "MMM D",
            },
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              return "Rs " + value.toLocaleString() // format y-axis label as currency
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            ": Rs " +
            tooltipItem.yLabel.toLocaleString() // format tooltip as currency
          )
        },
      },
    },
  }

  return (
    <div className="w-100 mt-5 mb-3">
      <div className="d-flex justify-content-between mb-3">
        <div>
          <h3 className="font-weight-bold">Total Revenue</h3>
          <p className="small m-0 font-italic">
            {startDate} - {endDate}
          </p>
          <div className="border p-3 mt-2">
            <p className="small m-0 ">
              <span className="mr-2 font-weight-bold">Cumulative Total:</span>
              Rs. {totalRevenue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </p>
            <p className="small m-0">
              <span className="mr-2 font-weight-bold">
                Average Daily Revenue:
              </span>
              Rs.{" "}
              {Math.floor(averageDailyRevenue)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </p>

            <p className="small m-0">
              <span className="mr-2 font-weight-bold">
                Highest Revenue Day:
              </span>
              {`${maxRevenueDay} - Rs. ${maxRevenueValue
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
            </p>

            <p className="small m-0">
              <span className="mr-2 font-weight-bold">
                Average Monthly Revenue:
              </span>
              Rs.{" "}
              {Math.floor(averageMonthlyRevenue)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </p>
          </div>
        </div>
      </div>
      <Line data={chartData} options={options} />
    </div>
  )
}

export default InvoiceChart
