export const ERROR_CODES = {
  1030: "GRID INTF",
  1019: "IGFOL-F",
  1013: "Grid Under Frequency",
  1037: "DC INJ-FAULT",
  1022: "UNB-BUS",
  1021: "OV-BUS",
  1033: "PV ISOLATION PRO 01,02",
  1010: "OV-G-V01",
  1032: "OVER TEM",
  1035: "RELAY-CHEK-FAIL",
  1011: "UN-G-V 01",
  1034: "Leakage Current Protection 01,02,03,04",
  1028: "REVERS - DC",
  1018: "OVG-V-I",
  1023: "DC Bus Under Voltage 01,02",
  1025: "OV-DCA-I",
  1027: "DC-INTF",
  1039: "ILeak-Check",
  "103A": "UN-TEM",
  1036: "DSP-B-Com-Fau",
  1038: "12Power-FAULT",
  1040: "AFCI-Check",
  1046: "GRID INTF02",
  1047: "IG-AD",
  "2043H": "State 2 excursion",
  "2019H": "MET_SEL_FAIL",
  "201AH": "CT Fault",
  "201H": "DRM_LINK_FAIL",
  "201BH": "DRM_CTL_Off",
  "2041H": "AFCI-Comm-Fail",
  "2042H": "AFCI-CTModule-Fail",
  IFC001: "Low Production",
  IFC002: "Tripped AC Side",
  IFC003: "Burnt Mark",
  IFC004: "Only Genarating  20W",
  IFC005: "Contineusly Soft Run",
  IFC006: "Contineusly  PID  Repairing",
  IFC007: "Inverter is NO POWER",
  IFC008: "Fan-H-Alarm",
  IFC009: "Meter Com Fail",
  IFC010: "Some MPPT Not working",
}
