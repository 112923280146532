import React, { useState, useEffect } from "react"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer"
import { Button } from "react-bootstrap"
import page_1 from "../../../images/trsf/page_1.png"
import page_2 from "../../../images/trsf/page_2.png"

import check from "../../../images/trsf/check.png"

import { FaFilePdf } from "react-icons/fa"

const Checkmark = ({}) => (
  <View>
    <Image src={check} style={styles.checkmark} />
  </View>
)

const TroubleshootingPDF = ({ data }) => {
  const [isClient, setClient] = useState(false)
  const [showDownloadLink, setShowDownloadLink] = useState(false)

  useEffect(() => {
    setClient(true)
    setShowDownloadLink(true)
  }, [])

  if (!isClient) return null

  const MyDocument = ({ data }) => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page_1} style={styles.pageImage} />

          {/* Basic Info */}

          <Text style={[styles.reportDate]}>
            {data.reportDate?.split("T")[0]}
          </Text>
          <Text style={[styles.installationDate]}>
            {data.installationDate?.split("T")[0]}
          </Text>
          <Text style={[styles.installerCompanyName]}>
            {data.installerCompanyName}
          </Text>
          <Text style={[styles.distributorCompanyName]}>
            {data.distributorCompanyName}
          </Text>

          {/* Model Info */}
          <Text style={[styles.modelType]}>{data.modelType}</Text>
          <Text style={[styles.serialNumber]}>{data.serialNumber}</Text>
          <Text style={[styles.invoiceNumber]}>{data.invoiceNumber}</Text>
          <Text style={[styles.errorDisplay]}>{data.errorCode}</Text>

          {/* Location Info */}
          <Text style={[styles.country]}>{data.country}</Text>
          <Text style={[styles.city]}>{data.city}</Text>
          <Text style={[styles.softwareVersion]}>{data.softwareVersion}</Text>
          <Text style={[styles.gridStandard]}>{data.gridStandard}</Text>

          {/* Error Info */}
          <Text style={[styles.errorCode]}>{data.errorCode}</Text>
          <Text style={[styles.errorMessage]}>{data.errorMessage}</Text>

          {/* LED Status */}
          {data.ledLightStatusPower && (
            <View style={styles.ledPower}>
              <Checkmark />
            </View>
          )}

          {data.ledLightStatusOperation && (
            <View style={styles.ledOperation}>
              <Checkmark />
            </View>
          )}
          {data.ledLightStatusAlarm && (
            <View style={styles.ledAlarm}>
              <Checkmark />
            </View>
          )}

          {/* Display Status */}
          {data.displayStatusOn && (
            <View style={styles.displayStatusOn}>
              <Checkmark />
            </View>
          )}
          {data.displayStatusOff && (
            <View style={styles.displayStatusOff}>
              <Checkmark />
            </View>
          )}

          {/* AC Voltage */}
          <Text style={[styles.acVoltageL1]}>{data.acVoltageL1}</Text>
          <Text style={[styles.acVoltageL2]}>{data.acVoltageL2}</Text>
          <Text style={[styles.acVoltageL3]}>{data.acVoltageL3}</Text>
          <Text style={[styles.acVoltageNPE]}>{data.acVoltageNPE}</Text>

          {/* PV Voltage */}
          <Text style={[styles.pvVoltageDc1]}>{data.pvVoltageDc1}</Text>
          <Text style={[styles.pvVoltageDc2]}>{data.pvVoltageDc2}</Text>
          <Text style={[styles.pvVoltageDc3]}>{data.pvVoltageDc3}</Text>
          <Text style={[styles.pvVoltageDc4]}>{data.pvVoltageDc4}</Text>

          {/* Grid Connection Type Checkmarks */}
          {data.gridConnectionSingle && (
            <View style={styles.gridConnectionSingle}>
              <Checkmark />
            </View>
          )}
          {data.gridConnectionThree && (
            <View style={styles.gridConnectionThree}>
              <Checkmark />
            </View>
          )}
          {data.gridConnectionTT && (
            <View style={styles.gridConnectionTT}>
              <Checkmark />
            </View>
          )}
          {data.gridConnectionTN && (
            <View style={styles.gridConnectionTN}>
              <Checkmark />
            </View>
          )}
          {data.gridConnectionIT && (
            <View style={styles.gridConnectionIT}>
              <Checkmark />
            </View>
          )}

          {/* PV String Voltage */}
          {/* {data.PvStringVoltage.map((checked, index) =>
            checked ? (
              <View
                key={index}
                style={{
                  position: "absolute",
                  top: 407 + index * 15,
                  left: 450,
                }}
              >
                <Checkmark />
              </View>
            ) : null
          )} */}

          <View style={styles.commentSection}>
            {[
              data?.comment1 && { text: data.comment1, key: 1 },
              data?.comment2 && { text: data.comment2, key: 2 },
              data?.comment3 && { text: data.comment3, key: 3 },
              data?.comment4 && { text: data.comment4, key: 4 },
              data?.comment5 && { text: data.comment5, key: 5 },
            ]
              .filter(Boolean) // Removes falsy values (null, undefined, empty strings)
              .map((comment, index) => (
                <Text
                  key={comment.key}
                  style={[styles[`comment${comment.key}`]]}
                >
                  {index + 1}. {comment.text}
                </Text>
              ))}
          </View>
        </View>
      </Page>

      {/* page 2 */}

      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page_2} style={styles.pageImage} />
          {/* Technical Details */}

          <Text style={[styles.technicianName]}>{data.technicianName}</Text>
          <Text style={[styles.technicianPhone]}>{data.technicianPhone}</Text>
          <Text style={[styles.technicianEmail]}>{data.technicianEmail}</Text>
          <Text style={[styles.technicianSignature]}>
            {data.technicianSignature}
          </Text>
        </View>
      </Page>
    </Document>
  )

  return (
    <div>
      {isClient && showDownloadLink && (
        <PDFDownloadLink
          document={<MyDocument data={data} />}
          fileName={`${data?.trsfNumber}.pdf`}
        >
          {({ loading }) => (
            <Button variant="primary" disabled={loading}>
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" />
                  Preparing Download...
                </>
              ) : (
                <>
                  <FaFilePdf className="mr-2" />
                  Download TS Form
                </>
              )}
            </Button>
          )}
        </PDFDownloadLink>
      )}
    </div>
  )
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    width: "100%",
    orientation: "portrait",
  },
  view_1: {
    position: "relative",
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
    fontSize: 9,
    color: "#3366CC",
  },
  pageImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: 0,
  },

  // Positions for each field
  reportDate: {
    position: "absolute",
    top: 123,
    left: 60,
  },
  installationDate: {
    position: "absolute",
    top: 123,
    left: 183,
  },
  installerCompanyName: {
    position: "absolute",
    top: 123,
    left: 280,
    width: 110,
    textAlign: "center",
    // marginHorizontal: 'auto'
  },
  distributorCompanyName: {
    position: "absolute",
    top: 123,
    left: 427,
    width: 130,
  },
  modelType: {
    position: "absolute",
    top: 167,
    left: 30,
  },
  serialNumber: {
    position: "absolute",
    top: 167,
    left: 160,
  },
  invoiceNumber: {
    position: "absolute",
    top: 167,
    left: 315,
  },
  errorDisplay: {
    position: "absolute",
    top: 167,
    left: 470,
  },
  country: {
    position: "absolute",
    top: 204,
    left: 60,
  },
  city: {
    position: "absolute",
    top: 204,
    left: 190,
  },
  softwareVersion: {
    position: "absolute",
    top: 204,
    left: 320,
  },
  gridStandard: {
    position: "absolute",
    top: 204,
    left: 465,
  },
  errorCode: {
    position: "absolute",
    top: 240,
    left: 149,
  },
  errorMessage: {
    position: "absolute",
    top: 240,
    left: 405,
  },
  // LED Status values
  ledPower: {
    position: "absolute",
    top: 253,
    left: 240,
  },
  ledOperation: {
    position: "absolute",
    top: 271,
    left: 240,
  },
  ledAlarm: {
    position: "absolute",
    top: 290,
    left: 240,
  },

  // Display Status
  displayStatusOn: {
    position: "absolute",
    top: 272,
    left: 464,
  },
  displayStatusOff: {
    position: "absolute",
    top: 272,
    left: 511,
  },

  // AC Voltage values
  acVoltageL1: {
    position: "absolute",
    top: 311,
    left: 235,
  },
  acVoltageL2: {
    position: "absolute",
    top: 328,
    left: 235,
  },
  acVoltageL3: {
    position: "absolute",
    top: 346,
    left: 235,
  },
  acVoltageNPE: {
    position: "absolute",
    top: 363,
    left: 235,
  },

  //Grid connection type

  gridConnectionSingle: {
    position: "absolute",
    top: 308,
    left: 468,
  },
  gridConnectionThree: {
    position: "absolute",
    top: 308,
    left: 543,
  },
  gridConnectionTT: {
    position: "absolute",
    top: 326,
    left: 468,
  },
  gridConnectionTN: {
    position: "absolute",
    top: 326,
    left: 543,
  },
  gridConnectionIT: {
    position: "absolute",
    top: 342,
    left: 468,
  },

  // PV Voltage values
  pvVoltageDc1: {
    position: "absolute",
    top: 380,
    left: 235,
  },
  pvVoltageDc2: {
    position: "absolute",
    top: 397,
    left: 235,
  },
  pvVoltageDc3: {
    position: "absolute",
    top: 414,
    left: 235,
  },
  pvVoltageDc4: {
    position: "absolute",
    top: 432,
    left: 235,
  },

  commentSection: {
    position: "absolute",
    top: 565,
    left: 40,
    width: 530,
    fontSize: 11,
  },

  comment1: {
    marginTop: 15,
  },

  comment2: {
    marginTop: 15,
  },

  comment3: {
    marginTop: 15,
  },

  comment4: {
    marginTop: 15,
  },

  comment5: {
    marginTop: 15,
  },

  // Technical Details
  technicianName: {
    position: "absolute",
    top: 457,
    left: 125,
  },
  technicianPhone: {
    position: "absolute",
    top: 481,
    left: 125,
  },
  technicianEmail: {
    position: "absolute",
    top: 506,
    left: 125,
  },
  technicianSignature: {
    position: "absolute",
    top: 531,
    left: 125,
  },

  checkmark: {
    width: 10,
    height: 15,
  },
})

export default React.memo(TroubleshootingPDF)
