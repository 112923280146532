import React, { useState } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import BackButton from "./backButton"
import { connect } from "react-redux"
import { Col, Button, Table } from "react-bootstrap"
import { editCustomer, deleteCustomer } from "../state/actions/customer"
import { confirmAlert } from "react-confirm-alert"

// Styles
import styles from "../styles/components/editCustomer.module.scss"
import { navigate } from "gatsby-link"

// Add Sri Lankan districts array
const sriLankanDistricts = [
  "Colombo",
  "Gampaha",
  "Kalutara",
  "Kandy",
  "Matale",
  "Nuwara Eliya",
  "Galle",
  "Matara",
  "Hambantota",
  "Jaffna",
  "Kilinochchi",
  "Mannar",
  "Vavuniya",
  "Mullaitivu",
  "Batticaloa",
  "Ampara",
  "Trincomalee",
  "Kurunegala",
  "Puttalam",
  "Anuradhapura",
  "Polonnaruwa",
  "Badulla",
  "Moneragala",
  "Ratnapura",
  "Kegalle",
]

const sriLankanProvinces = [
  "Western",
  "Southern",
  "Northern",
  "Eastern",
  "Central",
  "Sabaragamuwa",
  "North Western",
  "North Central",
  "Uva",
]

const EditCustomer = ({
  customer,
  editCustomer,
  deleteCustomer,
  admin,
  canEditCustomer,
}) => {
  const [edit, toggleEdit] = useState(false)
  const [customerCode, editCustomerCode] = useState(customer.code || "")
  const [customerName, editCustomerName] = useState(customer.name || "")
  const [customerAddress, editCustomerAddress] = useState(
    customer.address || ""
  )
  const [customerTel, editCustomerTel] = useState(customer.telephone || "")
  const [customerEmail, editCustomerEmail] = useState(customer.email || "")
  const [vatNumber, editVatNumber] = useState(customer.vatNumber || "")
  const [tinNumber, editTinNumber] = useState(customer.tinNumber || "")
  const [companyPV_number, editCompanyPV_number] = useState(
    customer.companyPV_number || ""
  )
  const [salesPerson, editSalesPerson] = useState(customer.salesPerson || "")
  const [district, editDistrict] = useState(customer.district || "")
  const [province, editProvince] = useState(customer.province || "")

  const handleUpdateCustomer = () => {
    if (!admin && !canEditCustomer) return

    const data = {
      id: customer._id,
      customerData: {
        code: customerCode,
        name: customerName,
        address: customerAddress,
        telephone: customerTel,
        vatNumber,
        tinNumber,
        companyPV_number,
        salesPerson,
        district,
        province,
      },
    }

    if (customerEmail) {
      data.customerData.email = customerEmail
    }
    editCustomer(data).then(success => {
      if (success) toggleEdit(false)
    })
  }

  const handleDeleteCustomer = () => {
    if (!admin && !canEditCustomer) return

    deleteCustomer(customer._id).then(success => {
      if (success) {
        navigate("/app/customers")
      }
    })
  }

  // Confirm Buttons

  const confirmUpdateCustomer = () => {
    confirmAlert({
      title: "Update Customer",
      message: "Are you sure you want to update this customer?",
      buttons: [
        {
          label: "No",
        },
        {
          label: "Yes",
          onClick: handleUpdateCustomer,
        },
      ],
    })
  }

  const confirmDeleteCustomer = () => {
    confirmAlert({
      title: "Delete Customer",
      message: "Are you sure you want to delete this customer?",
      buttons: [
        {
          label: "No",
        },
        {
          label: "Yes",
          onClick: handleDeleteCustomer,
        },
      ],
    })
  }

  return (
    <div className={styles.wrapper}>
      <Col>
        <h1 className="title">
          Customer <span>{customer.code}</span>
        </h1>
        <BackButton to="/app/customers" />
        <Table>
          <tbody className={styles.table}>
            <tr>
              <td className={styles.label}>Customer Code</td>
              {!edit ? (
                <td>{customer.code}</td>
              ) : (
                <td>
                  <input
                    type="text"
                    name="customerCode"
                    value={customerCode}
                    onChange={e => {
                      editCustomerCode(e.target.value.toUpperCase())
                    }}
                    id="customerCode"
                    autoFocus
                  />
                </td>
              )}
            </tr>
            <tr>
              <td className={styles.label}>Name</td>
              {!edit ? (
                <td>{customer.name}</td>
              ) : (
                <td>
                  <input
                    type="text"
                    name="customerName"
                    value={customerName}
                    onChange={e => {
                      editCustomerName(e.target.value)
                    }}
                    id="customerName"
                    autoFocus
                  />
                </td>
              )}
            </tr>

            <tr>
              <td className={styles.label}>Address</td>
              {!edit ? (
                <td>{customer.address}</td>
              ) : (
                <td>
                  <input
                    type="text"
                    name="customerAddress"
                    value={customerAddress}
                    onChange={e => {
                      editCustomerAddress(e.target.value)
                    }}
                    id="customerAddress"
                    autoFocus
                  />
                </td>
              )}
            </tr>

            <tr>
              <td className={styles.label}>Telephone</td>
              {!edit ? (
                <td>{customer.telephone}</td>
              ) : (
                <td>
                  <input
                    type="tel"
                    name="customerTel"
                    value={customerTel}
                    onChange={e => {
                      editCustomerTel(e.target.value)
                    }}
                    id="customerTel"
                    autoFocus
                  />
                </td>
              )}
            </tr>

            <tr>
              <td className={styles.label}>Email</td>
              {!edit ? (
                <td>{customer.email}</td>
              ) : (
                <td>
                  <input
                    type="email"
                    name="customerEmail"
                    value={customerEmail}
                    onChange={e => {
                      editCustomerEmail(e.target.value)
                    }}
                    id="customerEmail"
                    autoFocus
                  />
                </td>
              )}
            </tr>

            <tr>
              <td className={styles.label}>VAT Number</td>
              {!edit ? (
                <td>{customer.vatNumber}</td>
              ) : (
                <td>
                  <input
                    type="string"
                    name="vatNumber"
                    value={vatNumber}
                    onChange={e => {
                      editVatNumber(e.target.value)
                    }}
                    id="vatNumber"
                  />
                </td>
              )}
            </tr>

            <tr>
              <td className={styles.label}>TIN Number</td>
              {!edit ? (
                <td>{customer.tinNumber}</td>
              ) : (
                <td>
                  <input
                    type="string"
                    name="tinNumber"
                    value={tinNumber}
                    onChange={e => {
                      editTinNumber(e.target.value)
                    }}
                    id="tinNumber"
                  />
                </td>
              )}
            </tr>

            <tr>
              <td className={styles.label}>Company PV Number</td>
              {!edit ? (
                <td>{customer.companyPV_number}</td>
              ) : (
                <td>
                  <input
                    type="string"
                    name="companyPV_number"
                    value={companyPV_number}
                    onChange={e => {
                      editCompanyPV_number(e.target.value)
                    }}
                    id="companyPV_number"
                  />
                </td>
              )}
            </tr>

            <tr>
              <td className={styles.label}>Sales Person </td>
              {!edit ? (
                <td>{customer.salesPerson}</td>
              ) : (
                <td>
                  <input
                    type="string"
                    name="salesPerson"
                    value={salesPerson}
                    onChange={e => {
                      editSalesPerson(e.target.value)
                    }}
                    id="salesPerson"
                  />
                </td>
              )}
            </tr>

            <tr>
              <td className={styles.label}>District</td>
              {!edit ? (
                <td>{customer.district}</td>
              ) : (
                <td>
                  <select
                    name="district"
                    value={district}
                    onChange={e => {
                      editDistrict(e.target.value)
                    }}
                    id="district"
                    className="form-control"
                  >
                    <option value="">Select District</option>
                    {sriLankanDistricts.map(dist => (
                      <option key={dist} value={dist}>
                        {dist}
                      </option>
                    ))}
                  </select>
                </td>
              )}
            </tr>

            <tr>
              <td className={styles.label}>Province</td>
              {!edit ? (
                <td>{customer.province}</td>
              ) : (
                <td>
                  <select
                    name="province"
                    value={province}
                    onChange={e => editProvince(e.target.value)}
                    id="province"
                    className="form-control"
                  >
                    <option value="">Select Province</option>
                    {sriLankanProvinces.map(prov => (
                      <option key={prov} value={prov}>
                        {prov}
                      </option>
                    ))}
                  </select>
                </td>
              )}
            </tr>
          </tbody>
        </Table>
      </Col>
      {(admin || canEditCustomer) && (
        <div className="action-buttons">
          <Button
            size="lg"
            style={{ marginRight: "6px" }}
            variant="light"
            onClick={() => {
              toggleEdit(!edit)
            }}
          >
            {edit ? "Cancel" : "Edit Customer"}
          </Button>
          {edit && (
            <Button size="lg" onClick={confirmUpdateCustomer}>
              Save
            </Button>
          )}
          {!edit && (
            <Button
              size="lg"
              style={{ marginRight: "6px" }}
              variant="danger"
              onClick={confirmDeleteCustomer}
            >
              Delete Customer
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

EditCustomer.propTypes = {
  customer: PropTypes.object.isRequired,
}

// Specifies the default values for props:
EditCustomer.defaultProps = {
  customer: {
    code: "",
    name: "",
    address: "",
    telephone: "",
    email: "",
  },
}

const mapStateToProps = (state, props) => {
  return {
    customer: state.customer.customers.find(
      customer => customer._id === props.id
    ),
    admin: state.auth.auth.user.admin,
    canEditCustomer: state.auth.auth.user.customers,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editCustomer: data => dispatch(editCustomer(data)),
    deleteCustomer: data => dispatch(deleteCustomer(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer)
