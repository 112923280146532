import React, { useState } from "react"
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"
import moment from "moment"

const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY

// Sri Lanka districts with their approximate coordinates
const districtCoordinates = {
  Colombo: { lat: 6.9271, lng: 79.8612 },
  Gampaha: { lat: 7.0873, lng: 80.0141 },
  Kalutara: { lat: 6.5854, lng: 79.9607 },
  Kandy: { lat: 7.2906, lng: 80.6337 },
  Matale: { lat: 7.4675, lng: 80.6234 },
  "Nuwara Eliya": { lat: 6.9497, lng: 80.7891 },
  Galle: { lat: 6.0535, lng: 80.221 },
  Matara: { lat: 5.9549, lng: 80.555 },
  Hambantota: { lat: 6.1429, lng: 81.1212 },
  Jaffna: { lat: 9.6615, lng: 80.0255 },
  Kilinochchi: { lat: 9.3803, lng: 80.4036 },
  Mannar: { lat: 8.9833, lng: 79.9 },
  Vavuniya: { lat: 8.7514, lng: 80.4997 },
  Mullaitivu: { lat: 9.2717, lng: 80.8152 },
  Batticaloa: { lat: 7.717, lng: 81.7 },
  Ampara: { lat: 7.2917, lng: 81.675 },
  Trincomalee: { lat: 8.5667, lng: 81.2333 },
  Kurunegala: { lat: 7.4867, lng: 80.3647 },
  Puttalam: { lat: 8.0408, lng: 79.8394 },
  Anuradhapura: { lat: 8.3114, lng: 80.4037 },
  Polonnaruwa: { lat: 7.9403, lng: 81.0188 },
  Badulla: { lat: 6.9934, lng: 81.055 },
  Moneragala: { lat: 6.8667, lng: 81.35 },
  Ratnapura: { lat: 6.7056, lng: 80.3847 },
  Kegalle: { lat: 7.2513, lng: 80.3464 },
}

// Sri Lanka provinces with their approximate coordinates
const provinceCoordinates = {
  Western: { lat: 7.0, lng: 79.92 },
  Central: { lat: 7.35, lng: 80.75 },
  Southern: { lat: 6.25, lng: 80.6 },
  Northern: { lat: 9.25, lng: 80.5 },
  Eastern: { lat: 7.8, lng: 81.4 },
  "North Western": { lat: 7.6, lng: 80.0 },
  "North Central": { lat: 8.3, lng: 80.75 },
  Uva: { lat: 6.9, lng: 81.25 },
  Sabaragamuwa: { lat: 6.8, lng: 80.5 },
}

const containerStyle = {
  height: "500px",
  width: "100%",
  marginBottom: "20px",
}

const CustomerLocationMap = ({
  customers,
  startDate,
  endDate,
  invoiceData,
}) => {
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [filterType, setFilterType] = useState("district")

  const center = {
    lat: 7.8731, // Sri Lanka center coordinates
    lng: 80.7718,
  }

  const getLocationData = () => {
    const filteredInvoices = invoiceData.filter(invoice => {
      const invoiceDate = moment(invoice.date)
      return invoiceDate.isBetween(startDate, endDate, "day", "[]")
    })

    const customersWithTotals = customers.reduce((acc, customer) => {
      const customerInvoices = filteredInvoices.filter(
        invoice => invoice.customerName === customer.name
      )

      const totalAmount = customerInvoices.reduce(
        (sum, invoice) => sum + (invoice.invoiceTotal || 0),
        0
      )

      if (totalAmount > 0) {
        acc.push({
          ...customer,
          totalAmount,
        })
      }
      return acc
    }, [])

    const top10Customers = customersWithTotals
      .sort((a, b) => b.totalAmount - a.totalAmount)
      .slice(0, 10)

    return top10Customers.reduce((acc, customer) => {
      const locationKey =
        filterType === "district" ? customer.district : customer.province

      console.log(locationKey)

      const coordinates =
        filterType === "district"
          ? districtCoordinates[locationKey]
          : provinceCoordinates[locationKey]

      if (!locationKey || !coordinates) return acc

      if (!acc[locationKey]) {
        acc[locationKey] = {
          count: 1,
          customers: [customer],
          totalAmount: customer.totalAmount,
          lat: coordinates.lat,
          lng: coordinates.lng,
        }
      } else {
        acc[locationKey].count += 1
        acc[locationKey].customers.push(customer)
        acc[locationKey].totalAmount += customer.totalAmount
      }
      return acc
    }, {})
  }

  const locationData = getLocationData()

  return (
    <>
      <div>
        <label>Filter By: </label>
        <select
          onChange={e => setFilterType(e.target.value)}
          value={filterType}
        >
          <option value="district">District</option>
          <option value="province">Province</option>
        </select>
      </div>
      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={7}>
          {Object.entries(locationData).map(([location, data]) => (
            <Marker
              key={location}
              position={{ lat: data.lat, lng: data.lng }}
              onClick={() => setSelectedLocation(location)}
              label={data.count.toString()}
            />
          ))}

          {selectedLocation && locationData[selectedLocation] && (
            <InfoWindow
              position={{
                lat: locationData[selectedLocation].lat,
                lng: locationData[selectedLocation].lng,
              }}
              onCloseClick={() => setSelectedLocation(null)}
            >
              <div>
                <h3>
                  {selectedLocation}{" "}
                  {filterType === "district" ? "District" : "Province"}
                </h3>
                <p>Total Customers: {locationData[selectedLocation].count}</p>
                <p>
                  Total Revenue: Rs.{" "}
                  {locationData[selectedLocation].totalAmount.toLocaleString()}
                </p>
                {locationData[selectedLocation].customers.map(
                  (customer, index) => (
                    <div
                      key={index}
                      style={{
                        marginBottom: "10px",
                        borderBottom: "1px solid #eee",
                        paddingBottom: "5px",
                      }}
                    >
                      <p>
                        <strong>{customer.name}</strong>
                      </p>
                      <p>
                        Revenue: Rs. {customer.totalAmount.toLocaleString()}
                      </p>
                    </div>
                  )
                )}
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </>
  )
}

export default CustomerLocationMap
