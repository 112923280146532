import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import {
  FaArrowLeft,
  FaArrowRight,
  FaSearch,
  FaExchangeAlt,
} from "react-icons/fa"
import { Table, Row, Button } from "react-bootstrap"
import moment from "moment"
import { navigate } from "gatsby"
import { fetchReplacements } from "../../../state/actions/issueReplacement"
import Loading from "../../loading"

import styles from "../../../styles/components/technicalTeam/issueReplacements.module.scss"

const IssueReplacements = props => {
  const [currentPage, setCurrentPage] = useState(1)
  const [replacementsPerPage, setReplacementsPerPage] = useState(2)
  const [searchTerm, setSearchTerm] = useState("")
  const [value, setValue] = useState("")

  useEffect(() => {
    props.fetchReplacements({
      skip: currentPage,
      limit: replacementsPerPage,
      search: searchTerm,
    })
  }, [currentPage, replacementsPerPage, searchTerm])

  const replacementClickHandler = id => {
    navigate(`/app/issue-replacement/${id}`)
  }

  if (props.issueReplacements?.length === 0 && !searchTerm) {
    return <Loading />
  }

  const onSearch = searchName => {
    setSearchTerm(searchName)
    setValue(searchName)
    setCurrentPage(1)
  }

  return (
    <div className={styles.wrapper}>
      <div className="header">
        <h1 className="title">
          <FaExchangeAlt /> Issue Replacements
        </h1>
      </div>
      <div className="mt-5">
        {/* Search */}
        <div className={styles.search}>
          <input
            type="text"
            value={value}
            name="search"
            placeholder="Search"
            autoComplete="off"
            className={styles.searchInput}
            onChange={e => {
              setValue(e.target.value)
              setSearchTerm(e.target.value)
              setCurrentPage(1)
            }}
          />
          <FaSearch className={styles.searchIcon} />
        </div>{" "}
        <ul className={styles.dropdown}>
          {props.customers
            .filter(customer => {
              const searchName = value.toLowerCase()
              const customerName = customer.name.toLowerCase()

              return (
                searchName &&
                customerName.includes(searchName) &&
                customerName !== searchName
              )
            })
            .map(customer => (
              <div
                className={styles.dropdownRow}
                onClick={() => onSearch(customer?.name)}
              >
                {customer?.name}
              </div>
            ))}
        </ul>
        {props.issueReplacements && props.issueReplacements.length ? (
          <>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Replacement Number</th>
                  <th>Warranty Claim Number</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {props.issueReplacements.map((replacement, idx) => {
                  return (
                    <tr
                      style={{ cursor: "pointer" }}
                      key={idx}
                      onClick={() => replacementClickHandler(replacement._id)}
                    >
                      <td>{replacement.customerName}</td>
                      <td>{replacement.replacementNumber}</td>
                      <td>{replacement.warrantyClaimNumber}</td>
                      <td>
                        {moment(new Date(replacement.createdAt)).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {replacement.issued ? (
                          <span className="text-success">Issued</span>
                        ) : replacement.cancelled ? (
                          <span className="text-danger">Cancelled</span>
                        ) : (
                          <span className="">Pending</span>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>

            {/* Pagination */}
            <Row className="d-flex justify-content-center align-items-center my-4">
              <div className="d-flex align-items-center mx-4 my-4 my-lg-0">
                <h3
                  className="small d-inline mr-2"
                  style={{
                    minWidth: "105px",
                  }}
                >
                  Items per page:
                </h3>
                <select
                  className="form-control form-control-lg"
                  value={replacementsPerPage}
                  onChange={e => {
                    setCurrentPage(1)
                    setReplacementsPerPage(Number(e.target.value))
                  }}
                >
                  <option value="2">2</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <Row className="d-flex justify-content-center align-items-center mx-4">
                <Button
                  size="lg"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                  variant={currentPage === 1 ? "secondary" : "primary"}
                >
                  <FaArrowLeft />
                </Button>

                <span className="mx-3 small">
                  Page {currentPage} / {props.totalPages}
                </span>

                <Button
                  size="lg"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={
                    currentPage >= props.replacementTotal / replacementsPerPage
                  }
                  variant="primary"
                >
                  <FaArrowRight />
                </Button>
              </Row>
            </Row>
          </>
        ) : (
          <p className="text-muted">No replacements available!</p>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    issueReplacements: state.issueReplacement.replacements,
    replacementTotal: state.issueReplacement.totalReplacements,
    totalPages: state.issueReplacement.totalPages,
    isLoading: state.issueReplacement.isLoading,
    customers: state.customer.customers,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchReplacements: data => dispatch(fetchReplacements(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueReplacements)
