import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { Form } from "react-bootstrap"
import { connect } from "react-redux"
import _ from "lodash"
import {
  deleteProduct,
  setProductQuantity,
  setProductDiscount,
  setProductDiscountType,
  setProductIncludeSerialNumbers,
  setInvoiceWarrantyTime,
} from "../state/actions/invoice"
import {
  deleteQuoteProduct,
  setQuoteProductQuantity,
  setQuoteProductDiscount,
  setQuoteProductDiscountType,
  setQuoteWarrantyTime,
} from "../state/actions/quotation"

import {
  deleteReplacementProduct,
  setReplacementProductQuantity,
  setReplacementProductDiscount,
  setReplacementProductDiscountType,
  setReplacementProductIncludeSerialNumbers,
  setReplacementProductWarrantyTime,
} from "../state/actions/issueReplacement"
import { formatNumber, getTotal } from "../state/utils"
import SerialNumberModal from "./invoices/serialNumberModal"
import ReplacementSerialNumberModal from "./technicalTeam/replacementSerialNumberModal"

// Styles
import styles from "../styles/components/table.module.scss"
import { FaTimes } from "react-icons/fa"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const ProductTable = ({
  document,

  invoiceProducts,
  deleteInvoiceProduct,
  handleInvoiceChangeQuantity,
  handleInvoiceChangeDiscount,
  handleInvoiceChangeDiscountType,
  handleInvoiceIncludeSerialNumbers,
  handleInvoiceWarrantyTime,

  quoteProducts,
  deleteQuoteProduct,
  handleQuoteChangeQuantity,
  handleQuoteChangeDiscount,
  handleQuoteChangeDiscountType,
  handleQuoteWarrantyTime,

  issueReplacementProducts,
  deleteReplacementProduct,
  handleReplacementChangeQuantity,
  handleReplacementChangeDiscount,
  handleReplacementChangeDiscountType,
  handleReplacementIncludeSerialNumbers,
  handleReplacementWarrantyTime,
}) => {
  const [products, setProducts] = useState([])

  useEffect(() => {
    if (document === "quotation") {
      setProducts(quoteProducts)
    } else if (document === "replacement") {
      setProducts(issueReplacementProducts)
    } else {
      setProducts(invoiceProducts)
    }
  }, [invoiceProducts, quoteProducts, issueReplacementProducts])

  const calculateTotalPrice = (
    price,
    qty,
    discount,
    discountType,
    newWarranty
  ) => {
    // const addAmountForWarranty=newWarranty && newWarranty == '5 Years' ? (parseFloat(price) * 15/100) * parseFloat(qty) : 0

    const discountAmount =
      discountType === "LKR"
        ? discount * qty || 0
        : (price * qty * discount || 0) / 100
    if (isNaN(price) || isNaN(qty)) {
      return `Rs. 0`
    } else {
      return `Rs. ${formatNumber(price * qty - discountAmount)}`
    }
  }

  const classes = useStyles()
  return (
    <>
      <TableContainer component={Paper}>
        {products.length > 0 && (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Product Description</TableCell>
                <TableCell>Image</TableCell>
                {/* <TableCell >Category</TableCell> */}
                <TableCell>Manufacturer</TableCell>
                <TableCell>Warranty</TableCell>
                <TableCell>Country Of Origin</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell>Select Warranty</TableCell>

                <TableCell>Total</TableCell>
                {(document === "invoice" || document === "replacement") && (
                  <TableCell>Serial Numbers</TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {products &&
                _.map(products, (product, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{product.sku}</TableCell>
                    <TableCell>{product.productName}</TableCell>

                    <TableCell>{product.productDescription || "N/A"}</TableCell>
                    <TableCell>
                      {product.productImages && product.productImages[0] ? (
                        <div className={styles.img}>
                          <img
                            src={product.productImages[0].fields.file.url}
                            alt={product.productImages[0].fields.description}
                            style={{
                              display: "block",
                              maxWidth: "100%",
                              maxHeight: "100%",
                              width: "auto",
                              height: "auto",
                            }}
                          />
                        </div>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>

                    {/* <TableCell align="right">{product.category}</TableCell> */}
                    <TableCell>{product.manufacturer}</TableCell>
                    <TableCell>{product.warranty}</TableCell>
                    <TableCell>{product.countryOfOrigin}</TableCell>
                    <TableCell>{formatNumber(product.price)}</TableCell>
                    <TableCell>
                      <input
                        value={product.quantity}
                        type="number"
                        onChange={e => {
                          if (e.target.value < 1) return
                          if (document === "quotation") {
                            handleQuoteChangeQuantity(
                              product.id,
                              parseFloat(e.target.value)
                            )
                          } else if (document === "replacement") {
                            handleReplacementChangeQuantity(
                              product.id,
                              parseFloat(e.target.value)
                            )
                          } else {
                            handleInvoiceChangeQuantity(
                              product.id,
                              parseFloat(e.target.value)
                            )
                          }
                        }}
                        style={{ width: "60px" }}
                      />
                    </TableCell>
                    <TableCell>
                      <div>
                        <input
                          value={product.discount}
                          type="number"
                          onChange={e => {
                            if (
                              product.discountType === "%" &&
                              e.target.value > 100
                            )
                              return
                            if (document === "quotation") {
                              handleQuoteChangeDiscount(
                                product.id,
                                parseFloat(e.target.value)
                              )
                            } else if (document === "replacement") {
                              handleReplacementChangeDiscount(
                                product.id,
                                parseFloat(e.target.value)
                              )
                            } else {
                              handleInvoiceChangeDiscount(
                                product.id,
                                parseFloat(e.target.value)
                              )
                            }
                          }}
                        />
                        <select
                          onChange={e => {
                            if (document === "quotation") {
                              handleQuoteChangeDiscountType(
                                product.id,
                                e.target.value
                              )
                              handleQuoteChangeDiscount(
                                product.id,
                                parseFloat(0)
                              )
                            } else if (document === "replacement") {
                              handleReplacementChangeDiscountType(
                                product.id,
                                e.target.value
                              )
                              handleReplacementChangeDiscount(
                                product.id,
                                parseFloat(0)
                              )
                            } else {
                              handleInvoiceChangeDiscountType(
                                product.id,
                                e.target.value
                              )
                              handleInvoiceChangeDiscount(
                                product.id,
                                parseFloat(0)
                              )
                            }
                          }}
                          value={product.discountType}
                        >
                          <option value="%">%</option>
                          <option value="LKR">LKR</option>
                        </select>
                      </div>
                    </TableCell>
                    {document === "quotation" &&
                      product.category.includes("Inverter") && (
                        <TableCell>
                          <select
                            value={product.newWarranty && product.newWarranty}
                            onChange={e => {
                              handleQuoteWarrantyTime(
                                product.id,
                                e.target.value
                              )
                            }}
                          >
                            {/* <option >Select Warranty Tme</option> */}
                            <option value="10 Years">10 years</option>
                            <option value="5 Years">5 years</option>
                          </select>
                        </TableCell>
                      )}
                    {document === "invoice" &&
                      product.category.includes("Inverter") && (
                        <TableCell>
                          <select
                            value={product.newWarranty && product.newWarranty}
                            onChange={e => {
                              handleInvoiceWarrantyTime(
                                product.id,
                                e.target.value
                              )
                            }}
                          >
                            {/* <option >Select Warranty Tme</option> */}
                            <option value="10 Years">10 years</option>
                            <option value="5 Years">5 years</option>
                          </select>
                        </TableCell>
                      )}

                    {document === "replacement" &&
                      product.category.includes("Inverter") && (
                        <TableCell>
                          <select
                            value={product.newWarranty && product.newWarranty}
                            onChange={e => {
                              handleReplacementWarrantyTime(
                                product.id,
                                e.target.value
                              )
                            }}
                          >
                            {/* <option >Select Warranty Tme</option> */}
                            <option value="10 Years">10 years</option>
                            <option value="5 Years">5 years</option>
                          </select>
                        </TableCell>
                      )}
                    {!product.category.includes("Inverter") && (
                      <TableCell></TableCell>
                    )}
                    <TableCell>
                      {calculateTotalPrice(
                        product.price,
                        product.quantity,
                        product.discount,
                        product.discountType,
                        product.newWarranty
                      )}
                    </TableCell>
                    {document === "invoice" && (
                      <TableCell>
                        <div className={styles.serialNumberCheckbox}>
                          <Form.Group controlId="serailCheckbox">
                            <Form.Check
                              type="checkbox"
                              label="Include Serial Numbers"
                              value={product.includeSerialNumbers}
                              onChange={() =>
                                handleInvoiceIncludeSerialNumbers(
                                  product.id,
                                  !product.includeSerialNumbers
                                )
                              }
                            />
                          </Form.Group>
                          {product.includeSerialNumbers && (
                            <>
                              <p className="mt-2 mb-1">
                                Count: {product.serialNumbersInInvoice.length}
                              </p>
                              <SerialNumberModal id={product.id} />
                            </>
                          )}
                        </div>
                      </TableCell>
                    )}

                    {document === "replacement" && (
                      <TableCell>
                        <div className={styles.serialNumberCheckbox}>
                          <Form.Group controlId="serailCheckbox">
                            <Form.Check
                              type="checkbox"
                              label="Include Serial Numbers"
                              value={product.includeSerialNumbers}
                              onChange={() =>
                                handleReplacementIncludeSerialNumbers(
                                  product.id,
                                  !product.includeSerialNumbers
                                )
                              }
                            />
                          </Form.Group>
                          {product.includeSerialNumbers && (
                            <>
                              <p className="mt-2 mb-1">
                                Count: {product.serialNumbersInIR.length}
                              </p>
                              <ReplacementSerialNumberModal id={product.id} />
                            </>
                          )}
                        </div>
                      </TableCell>
                    )}

                    <TableCell>
                      <FaTimes
                        color="red"
                        style={{ cursor: "pointer", fontSize: "12" }}
                        onClick={() => {
                          if (document === "quotation") {
                            deleteQuoteProduct(product.id)
                          } else if (document === "replacement") {
                            deleteReplacementProduct(product.id)
                          } else {
                            deleteInvoiceProduct(product.id)
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}

        {products.length > 0 && (
          <>
            <div className={styles.total2}>
              <p>
                Subtotal:
                <span className={styles.amount}>
                  Rs. {products && formatNumber(getTotal(products))}
                </span>
              </p>
            </div>
            {
              // if year is 2024, add 18% VAT
              <>
                {/* 18% Vat */}
                <div className={styles.total2}>
                  <p>
                    18% VAT:
                    <span className={styles.amount}>
                      Rs. {products && formatNumber(getTotal(products) * 0.18)}
                    </span>
                  </p>
                </div>

                <div className={styles.total}>
                  <p>
                    Total:
                    <span className={styles.amount}>
                      Rs. {products && formatNumber(getTotal(products) * 1.18)}
                    </span>
                  </p>
                </div>
              </>
            }
          </>
        )}
      </TableContainer>
      {products.length === 0 && (
        <div>
          <p>Please add a product to get started</p>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    invoiceProducts: state.invoice.invoiceProducts,
    quoteProducts: state.quotation.newQuotation.quotationProducts,
    issueReplacementProducts: state.issueReplacement.replacementProducts,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteInvoiceProduct: data => dispatch(deleteProduct(data)),
    handleInvoiceChangeQuantity: (id, quantity) =>
      dispatch(setProductQuantity(id, quantity)),
    handleInvoiceChangeDiscount: (id, discount) =>
      dispatch(setProductDiscount(id, discount)),
    handleInvoiceChangeDiscountType: (id, discountType) =>
      dispatch(setProductDiscountType(id, discountType)),
    handleInvoiceIncludeSerialNumbers: (id, includeSerialNumbers) =>
      dispatch(setProductIncludeSerialNumbers(id, includeSerialNumbers)),
    handleInvoiceWarrantyTime: (id, newWarranty) =>
      dispatch(setInvoiceWarrantyTime(id, newWarranty)),

    deleteQuoteProduct: data => dispatch(deleteQuoteProduct(data)),
    handleQuoteChangeQuantity: (id, quantity) =>
      dispatch(setQuoteProductQuantity(id, quantity)),
    handleQuoteChangeDiscount: (id, discount) =>
      dispatch(setQuoteProductDiscount(id, discount)),
    handleQuoteChangeDiscountType: (id, discountType) =>
      dispatch(setQuoteProductDiscountType(id, discountType)),
    handleQuoteWarrantyTime: (id, newWarranty) =>
      dispatch(setQuoteWarrantyTime(id, newWarranty)),

    deleteReplacementProduct: data => dispatch(deleteReplacementProduct(data)),
    handleReplacementChangeQuantity: (id, quantity) =>
      dispatch(setReplacementProductQuantity(id, quantity)),
    handleReplacementChangeDiscount: (id, discount) =>
      dispatch(setReplacementProductDiscount(id, discount)),
    handleReplacementChangeDiscountType: (id, discountType) =>
      dispatch(setReplacementProductDiscountType(id, discountType)),
    handleReplacementIncludeSerialNumbers: (id, includeSerialNumbers) =>
      dispatch(
        setReplacementProductIncludeSerialNumbers(id, includeSerialNumbers)
      ),
    handleReplacementWarrantyTime: (id, warrantyTime) =>
      dispatch(setReplacementProductWarrantyTime(id, warrantyTime)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTable)
