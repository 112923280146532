import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { toast } from "react-toastify"
import { connect } from "react-redux"
import { setProducts } from "../state/actions/product"
import { addProduct } from "../state/actions/invoice"
import { addQuoteProduct } from "../state/actions/quotation"
import { addCreditNoteProduct } from "../state/actions/creditNote"
import { setReplacementProducts } from "../state/actions/replacementProduct"
import { addReplacementProduct } from "../state/actions/issueReplacement"

// Styles
import styles from "../styles/components/table.module.scss"
import { FaSearch, FaWindowClose } from "react-icons/fa"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const ProductTable = ({
  document,
  setProducts,
  handleClose,
  products,
  addInvoiceProduct,
  addQuoteProduct,
  addCreditNoteProduct,
  replacementProducts,
  setReplacementProducts,
  addReplacementProduct,
}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const classes = useStyles()

  useEffect(() => {
    setProducts()
  }, [setProducts])

  useEffect(() => {
    setReplacementProducts()
  }, [setReplacementProducts])

  const quickSearch = e => {
    setSearchTerm(e.target.value)
  }

  const productsArray = []

  if (document === "replacement") {
    productsArray.push(replacementProducts)
  } else {
    productsArray.push(products)
  }

  return (
    <>
      <div className={styles.search}>
        <div style={{ position: "relative" }}>
          <input
            type="text"
            value={searchTerm}
            name="search"
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search Products"
            autoComplete="off"
            className={styles.searchInput}
          />
          <FaWindowClose
            className={styles.clear}
            onClick={() => {
              setSearchTerm("")
            }}
          />
        </div>

        <ul className={styles.quickSelect}>
          <li>
            <button
              onClick={e => {
                quickSearch(e)
              }}
              value=""
            >
              All Products
            </button>
          </li>
          <li>
            <button
              onClick={e => {
                quickSearch(e)
              }}
              value="Solar Inverter"
            >
              Inverters
            </button>
          </li>
          <li>
            <button
              onClick={e => {
                quickSearch(e)
              }}
              value="PV Modules"
            >
              Panels
            </button>
          </li>
          <li>
            <button
              onClick={e => {
                quickSearch(e)
              }}
              value="Railings &amp; Accessories"
            >
              Railings
            </button>
          </li>
          <li>
            <button
              onClick={e => {
                quickSearch(e)
              }}
              value="Electrical Accessories"
            >
              Electrical
            </button>
          </li>
          <li>
            <button
              onClick={e => {
                quickSearch(e)
              }}
              value="Hardware Accessories"
            >
              Hardware
            </button>
          </li>
          <li>
            <button
              onClick={e => {
                quickSearch(e)
              }}
              value="DC &amp; AC Cables"
            >
              DC &amp; AC
            </button>
          </li>
          <li>
            <button
              onClick={e => {
                quickSearch(e)
              }}
              value="Batteries"
            >
              Batteries
            </button>
          </li>
          <li>
            <button
              onClick={e => {
                quickSearch(e)
              }}
              value="Solar Water Pump"
            >
              Water Pumps
            </button>
          </li>
          <li>
            <button
              onClick={e => {
                quickSearch(e)
              }}
              value="Tools & Accessories"
            >
              Tools & Accessories
            </button>
          </li>
        </ul>
      </div>

      <TableContainer
        component={Paper}
        style={{ margin: "20px 0", minHeight: "90%" }}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>SKU</TableCell>
              <TableCell align="right">Product Name</TableCell>
              <TableCell align="right">Product Description</TableCell>
              <TableCell align="right">Image</TableCell>
              <TableCell align="right">Category</TableCell>
              <TableCell align="right">Manufacturer</TableCell>
              <TableCell align="right">Warranty</TableCell>
              <TableCell align="right">Country Of Origin</TableCell>
              <TableCell align="right">Stock</TableCell>
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productsArray &&
              productsArray[0]
                .filter(node => {
                  const name = node.productName
                    ? node.productName.trim().toLowerCase()
                    : ""
                  const sku = node.sku ? node.sku.trim().toLowerCase() : ""
                  const category = node.category
                    ? node.category.trim().toLowerCase()
                    : ""
                  const desc = node.productDescription
                    ? node.productDescription.trim().toLowerCase()
                    : ""
                  let search = searchTerm ? searchTerm.trim().toLowerCase() : ""

                  if (
                    name.includes(search) ||
                    sku.includes(search) ||
                    desc.includes(search) ||
                    category.includes(search)
                  ) {
                    return node
                  } else return null
                })
                // deleted products are not allowed
                .filter(
                  product =>
                    product.deleted === false || product.deleted === undefined
                )
                .map(node => (
                  <TableRow
                    key={node.sku}
                    onClick={() => {
                      if (document === "quotation") {
                        addQuoteProduct(node.id)
                      } else if (document === "creditNote") {
                        addCreditNoteProduct(node.id)
                      } else if (document === "replacement") {
                        if (node.stock < 1) {
                          toast.error("Product is out of stock!")
                          return
                        }
                        addReplacementProduct(node?.id)
                      } else {
                        if (node?.stock < 1) {
                          toast.error("Product is out of stock!")
                          return
                        }
                        addInvoiceProduct(node.id)
                      }
                      handleClose()
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" scope="row">
                      {node.sku}
                    </TableCell>
                    <TableCell align="right">{node.productName}</TableCell>

                    <TableCell align="right">
                      {node.productDescription}
                    </TableCell>
                    <TableCell align="right">
                      {node.productImages &&
                        node.productImages.map((image, i) => (
                          <div className={styles.img} key={i}>
                            <img
                              src={image.fields.file.url}
                              alt={image.fields.description}
                              style={{
                                display: "block",
                                maxWidth: "100%",
                                maxHeight: "100%",
                                width: "auto",
                                height: "auto",
                              }}
                            />
                          </div>
                        ))}
                    </TableCell>
                    <TableCell align="right">{node.category}</TableCell>
                    <TableCell align="right">{node.manufacturer}</TableCell>
                    <TableCell align="right" style={{ maxWidth: "150px" }}>
                      {node.warranty}
                    </TableCell>
                    <TableCell align="right">{node.countryOfOrigin}</TableCell>
                    <TableCell align="right">{node.stock}</TableCell>
                    <TableCell align="right">{node.price}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const mapStateToProps = state => {
  return {
    products: state.product.products,
    replacementProducts: state.replacementProduct.replacementProducts,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setProducts: data => dispatch(setProducts(data)),
    addInvoiceProduct: data => dispatch(addProduct(data)),
    addQuoteProduct: data => dispatch(addQuoteProduct(data)),
    addCreditNoteProduct: data => dispatch(addCreditNoteProduct(data)),
    setReplacementProducts: data => dispatch(setReplacementProducts(data)),
    addReplacementProduct: data => dispatch(addReplacementProduct(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTable)
