import _ from "lodash"
import axios from "axios"
import { toast } from "react-toastify"
import { navigate } from "gatsby-link"

export const setReplacementProducts = () => {
  return async (dispatch, getState) => {
    if (
      getState().replacementProduct.replacementProducts &&
      getState().replacementProduct.replacementProducts.length > 0
    ) {
      return
    }

    try {
      const [
        dataResponse,
        imagesResponse,
        databaseResponse,
      ] = await Promise.all([
        axios.get(
          `https://cdn.contentful.com/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}&content_type=replacementProduct&limit=1000&order=-fields.price`
        ),
        axios.get(
          `https://cdn.contentful.com/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/master/assets?access_token=${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}&limit=1000`
        ),
        axios.get(`${process.env.GATSBY_API_CALL}/replacement-products`, {
          headers: {
            Authorization: `Bearer ${getState().auth.auth.token}`,
          },
        }),
      ])

      const data = dataResponse.data
      const images = imagesResponse.data
      const productsFromDB = databaseResponse.data

      let newProducts = data.items.map(product => {
        const imageArray = product.fields.productImages?.map(image => {
          const imageLocation = images.items.find(
            asset => asset.sys.id === image.sys.id
          )
          return imageLocation
        })

        const productFromDB = productsFromDB.find(
          prod => prod.sku === product.fields.sku
        )

        return {
          ...product.fields,
          id: product.sys.id,
          cost: parseFloat(productFromDB?.cost),
          price: parseFloat(productFromDB?.price),
          productImages: imageArray,
          stock: productFromDB?.stock,
          serialNumbers: productFromDB?.serialNumbers,
          stockInTransit: productFromDB?.stockInTransit,
          serialNumbersInTransit: productFromDB?.serialNumbersInTransit,
          stockIssued: productFromDB?.stockIssued,
          serialNumbersIssued: productFromDB?.serialNumbersIssued,
          stockInHand: productFromDB?.stockInHand,
          stockTransferred: productFromDB?.stockTransferred,
          serialNumbersTransferred: productFromDB?.serialNumbersTransferred,
        }
      })

      dispatch({
        type: "SET_REPLACEMENT_PRODUCTS",
        data: newProducts,
      })
    } catch (e) {
      console.log(e)
      toast.error(`Error: ${e.response?.data.message}`)
    }
  }
}

// Get price, cost, stock, and serial numbers from the database for replacement products
export const setReplacementProductsFromDatabase = () => {
  return async (dispatch, getState) => {
    if (!getState().replacementProduct.replacementProducts.length) return

    try {
      const token = getState().auth.auth.token

      const fromDatabase = await axios.get(
        `${process.env.GATSBY_API_CALL}/replacement-products`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const replacementProductsFromDB = fromDatabase.data

      const newReplacementProducts = getState().replacementProduct.replacementProducts.map(
        product => {
          const productFromDB = replacementProductsFromDB.find(
            prod => prod.sku === product.sku
          )

          product.cost = parseFloat(productFromDB?.cost)
          product.price = parseFloat(productFromDB?.price)
          product.stock = productFromDB?.stock
          product.serialNumbers = productFromDB?.serialNumbers
          product.stockInTransit = productFromDB?.stockInTransit
          product.serialNumbersInTransit = productFromDB?.serialNumbersInTransit
          product.stockIssued = productFromDB?.stockIssued
          product.serialNumbersIssued = productFromDB?.serialNumbersIssued

          return product
        }
      )

      dispatch({
        type: "SET_REPLACEMENT_PRODUCTS",
        data: newReplacementProducts,
      })
    } catch (e) {
      console.log(e)
      toast.error(`Error: ${e.response?.data.message}`)
    }
  }
}

// Update Price
export const updateReplacementProductPrice = ({ productSKU, price }) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    dispatch({
      type: "SET_EDIT_REPLACEMENT_PRODUCT_LOADING",
      data: true,
    })
    const productData = {
      price: parseFloat(price),
    }

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/replacement-product/price/${productSKU}`,
        productData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const newProducts = [...getState().replacementProduct.replacementProducts]
      _.forEach(newProducts, product => {
        if (product.sku === productSKU) {
          product.price = price
        }
      })

      dispatch({
        type: "SET_REPLACEMENT_PRODUCTS",
        data: newProducts,
      })
      toast.success(`Price successfully updated`)
    } catch (e) {
      toast.error(`Error: ${e.response.data.message}`)
    }
    dispatch({
      type: "SET_EDIT_REPLACEMENT_PRODUCT_LOADING",
      data: false,
    })
  }
}

// Update Cost
export const updateCost = ({ productSKU, cost }) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    dispatch({
      type: "SET_EDIT_REPLACEMENT_PRODUCT_LOADING",
      data: true,
    })

    const productData = {
      cost: parseFloat(cost),
    }

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/replacement-product/price/${productSKU}`,
        productData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const newProducts = [...getState().replacementProduct.replacementProducts]
      _.forEach(newProducts, product => {
        if (product.sku === productSKU) {
          product.cost = cost
        }
      })

      dispatch({
        type: "SET_REPLACEMENT_PRODUCTS",
        data: newProducts,
      })

      toast.success(`Cost successfully updated`)
    } catch (e) {
      console.log(e.response)
      toast.error(`Error: ${e.response}`)
    }
    dispatch({
      type: "SET_EDIT_REPLACEMENT_PRODUCT_LOADING",
      data: false,
    })
  }
}

// Update Stock and Serial Numbers
export const updateReplacementStock = ({
  productSKU,
  stock,
  serialNumbers,
}) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    dispatch({
      type: "SET_EDIT_REPLACEMENT_PRODUCT_LOADING",
      data: true,
    })

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/replacement-product/${productSKU}`,
        {
          stock,
          serialNumbers,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const newProducts = [...getState().replacementProduct.replacementProducts]
      _.forEach(newProducts, product => {
        if (product.sku === productSKU) {
          product.stock = stock
          product.serialNumbers = serialNumbers
        }
      })

      dispatch({
        type: "SET_REPLACEMENT_PRODUCTS",
        data: newProducts,
      })
      toast.success(`Stock successfully updated`)
    } catch (e) {
      toast.error(`Error: ${e.response.data.message}`)
    }
    dispatch({
      type: "SET_EDIT_REPLACEMENT_PRODUCT_LOADING",
      data: false,
    })
  }
}

// Delete a replacement product
export const deleteReplacementProduct = productSKU => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    const products = getState().replacementProduct.replacementProducts

    dispatch({
      type: "SET_EDIT_REPLACEMENT_PRODUCT_LOADING",
      data: true,
    })

    try {
      const entry = products.find(item => item?.sku === productSKU.productSKU)

      if (!entry) {
        throw new Error("Replacement product not found")
      }

      const entryId = entry?.id

      await axios.put(
        `${process.env.GATSBY_API_CALL}/delete-replacement-product/${productSKU.productSKU}`,
        {
          sku: productSKU.productSKU,
          entryId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const remainingProducts = getState().replacementProduct.replacementProducts.filter(
        product => product.sku !== productSKU.productSKU
      )

      dispatch({
        type: "SET_REPLACEMENT_PRODUCTS",
        data: remainingProducts,
      })

      toast.success(`Replacement product successfully deleted!`)

      setTimeout(async () => {
        await navigate("/app/replacement-products")
      }, 1000)
    } catch (e) {
      console.log(e)
      toast.error(`Error: ${e}`)
    }
    dispatch({
      type: "SET_EDIT_REPLACEMENT_PRODUCT_LOADING",
      data: false,
    })
  }
}

// Transfer Products
export const transferReplacementProduct = (sku, transferData) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    dispatch({
      type: "SET_EDIT_REPLACEMENT_PRODUCT_LOADING",
      data: true,
    })

    try {
      const response = await axios.post(
        `${process.env.GATSBY_API_CALL}/replacement-products/transfer/${sku}`,
        transferData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      // Update both replacement products and main products in state
      const newReplacementProducts = [
        ...getState().replacementProduct.replacementProducts,
      ]

      _.forEach(newReplacementProducts, product => {
        if (product.sku === sku) {
          // Update stock
          product.stock -= transferData.quantity

          product.stockTransferred += transferData.quantity

          // Remove transferred serial numbers
          if (transferData.serialNumbers?.length > 0) {
            product.serialNumbers = product.serialNumbers.filter(
              sn =>
                !transferData.serialNumbers.some(
                  transferSN => transferSN.main === sn.main
                )
            )

            // Add to transferred serial numbers array
            product.serialNumbersTransferred = [
              ...(product.serialNumbersTransferred || []),
              ...transferData.serialNumbers,
            ]
          }
        }
      })

      dispatch({
        type: "SET_REPLACEMENT_PRODUCTS",
        data: newReplacementProducts,
      })

      toast.success("Products transferred successfully")
    } catch (error) {
      console.error("Transfer error:", error)
      toast.error(
        error.response?.data?.message || "Failed to transfer products"
      )
    } finally {
      dispatch({
        type: "SET_EDIT_REPLACEMENT_PRODUCT_LOADING",
        data: false,
      })
    }
  }
}
