import { toast } from "react-toastify"
import axios from "axios"
import _ from "lodash"

export const setTroubleshootings = data => {
  return async (dispatch, getState) => {
    const skip = data?.skip || 1
    const limit = data?.limit || 25
    const search = data?.search || ""

    dispatch({
      type: "SET_TROUBLESHOOTING_LOADING",
      data: true,
    })

    const token = getState().auth.auth.token

    try {
      let searchParam = search

      if (search !== "" && !/^\d+$/.test(search)) {
        // Assume it's a customer name and try to find the customer codes
        const customers = getState().customer.customers

        // Find matching customers based on a case-insensitive substring match
        const matchingCustomers = customers.filter(customer =>
          customer.name.toLowerCase().includes(search.toLowerCase())
        )

        // Take the top result as the search parameter
        const topCustomer = matchingCustomers[0]

        // Use the customer code of the top result as the search term
        if (topCustomer) {
          searchParam = topCustomer.name
        }
      }
      const response = await axios.get(
        `${
          process.env.GATSBY_API_CALL
        }/troubleShootings?page=${skip}&limit=${limit}${
          searchParam ? `&search=${searchParam}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      dispatch({
        type: "SET_TROUBLESHOOTINGS",
        data: {
          troubleshootings: response.data.data.troubleshootings,
          totalTroubleshootings: response.data.data.totalTroubleshootings,
          totalPages: response.data.data.totalPages,
        },
      })
    } catch (error) {
      console.error(error)
      toast.error(`Error: ${error.response?.data?.message || error.message}`)
      dispatch({
        type: "SET_TROUBLESHOOTING_ERROR",
        data: error.response?.data?.message || error.message,
      })
    } finally {
      dispatch({
        type: "SET_TROUBLESHOOTING_LOADING",
        data: false,
      })
    }
  }
}

export const getTroubleshootingById = id => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      const response = await axios.get(
        `${process.env.GATSBY_API_CALL}/troubleshooting/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch({
        type: "SET_CURRENT_TROUBLESHOOTING",
        data: response.data,
      })
      return response.data
    } catch (error) {
      toast.error(`Error: ${error.response?.data?.message || error.message}`)
      throw error
    }
  }
}

export const createTroubleshooting = formData => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      const response = await axios.post(
        `${process.env.GATSBY_API_CALL}/troubleshootings`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const newTroubleshootings = [
        response.data,
        ...getState().troubleshooting.troubleshootings,
      ]
      dispatch({
        type: "SET_TROUBLESHOOTINGS",
        data: newTroubleshootings,
      })

      return response.data
    } catch (error) {
      toast.error(`Error: ${error.response?.data?.message || error.message}`)
      throw error
    }
  }
}

export const updateTroubleshootingById = (id, formData) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    dispatch({
      type: "SET_EDIT_TROUBLESHOOTING_LOADING",
      data: true,
    })

    try {
      const response = await axios.put(
        `${process.env.GATSBY_API_CALL}/troubleshooting/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      // Update troubleshootings list
      const newTroubleshootings = [
        ...getState().troubleshooting.troubleshootings,
      ]
      const updatedTroubleshootings = newTroubleshootings.map(item =>
        item._id === response.data._id ? response.data : item
      )

      dispatch({
        type: "SET_TROUBLESHOOTINGS",
        data: updatedTroubleshootings,
      })

      // Update current troubleshooting
      dispatch({
        type: "SET_CURRENT_TROUBLESHOOTING",
        payload: response.data,
      })

      return response.data
    } catch (error) {
      console.log(error)

      toast.error(`Error: ${error.response?.data?.message || error.message}`)
      throw error
    } finally {
      dispatch({
        type: "SET_EDIT_TROUBLESHOOTING_LOADING",
        data: false,
      })
    }
  }
}

export const updateTroubleshootingStatus = (id, status) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    dispatch({
      type: "SET_EDIT_TROUBLESHOOTING_LOADING",
      data: true,
    })

    try {
      const response = await axios.patch(
        `${process.env.GATSBY_API_CALL}/troubleshooting/${id}/status`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      // Update troubleshootings list
      const troubleshootings = [...getState().troubleshooting.troubleshootings]
      const updatedTroubleshootings = troubleshootings.map(item =>
        item._id === response.data._id ? response.data : item
      )

      dispatch({
        type: "SET_TROUBLESHOOTINGS",
        data: updatedTroubleshootings,
      })

      // Update current troubleshooting if it's the one being updated
      if (getState().troubleshooting.currentTroubleshooting?._id === id) {
        dispatch({
          type: "SET_CURRENT_TROUBLESHOOTING",
          data: response.data,
        })
      }

      toast.success(`Troubleshooting status updated to ${status}`)
      return response.data
    } catch (error) {
      toast.error(`Error: ${error.response?.data?.message || error.message}`)
      throw error
    } finally {
      dispatch({
        type: "SET_EDIT_TROUBLESHOOTING_LOADING",
        data: false,
      })
    }
  }
}

// // Cancel

// export const cancelTroubleshooting = id => {
//   return async (dispatch, getState) => {
//     const token = getState().auth.auth.token
//     dispatch({
//       type: "SET_EDIT_TROUBLESHOOTING_LOADING",
//       data: true,
//     })

//     try {
//       const response = await axios.patch(
//         `${process.env.GATSBY_API_CALL}/troubleshooting/${id}/cancel`,
//         {},
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )

//       // Update troubleshootings list
//       const troubleshootings = [...getState().troubleshooting.troubleshootings]
//       const updatedTroubleshootings = troubleshootings.map(item =>
//         item._id === response.data._id ? response.data : item
//       )

//       dispatch({
//         type: "SET_TROUBLESHOOTINGS",
//         data: updatedTroubleshootings,
//       })

//       // Update current troubleshooting if it's the one being cancelled
//       if (getState().troubleshooting.currentTroubleshooting?._id === id) {
//         dispatch({
//           type: "SET_CURRENT_TROUBLESHOOTING",
//           data: response.data,
//         })
//       }

//       toast.success("Troubleshooting form cancelled successfully")
//       return response.data
//     } catch (error) {
//       toast.error(`Error: ${error.response?.data?.message || error.message}`)
//       throw error
//     } finally {
//       dispatch({
//         type: "SET_EDIT_TROUBLESHOOTING_LOADING",
//         data: false,
//       })
//     }
//   }
// }

export const confirmReplacement = id => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    dispatch({
      type: "SET_EDIT_TROUBLESHOOTING_LOADING",
      data: true,
    })

    try {
      const response = await axios.patch(
        `${process.env.GATSBY_API_CALL}/troubleshooting/${id}/confirmReplacement`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      // Update troubleshootings list
      const troubleshootings = [...getState().troubleshooting.troubleshootings]
      const updatedTroubleshootings = troubleshootings.map(item =>
        item._id === response.data._id ? response.data : item
      )

      dispatch({
        type: "SET_TROUBLESHOOTINGS",
        data: updatedTroubleshootings,
      })

      // Update current troubleshooting if it's the one being confirmed
      if (getState().troubleshooting.currentTroubleshooting?._id === id) {
        dispatch({
          type: "SET_CURRENT_TROUBLESHOOTING",
          data: response.data,
        })
      }

      toast.success("Troubleshooting replacement confirmed successfully")
      return response.data
    } catch (error) {
      toast.error(`Error: ${error.response?.data?.message || error.message}`)
      throw error
    } finally {
      dispatch({
        type: "SET_EDIT_TROUBLESHOOTING_LOADING",
        data: false,
      })
    }
  }
}

export const deleteTroubleshooting = id => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      await axios.delete(
        `${process.env.GATSBY_API_CALL}/troubleshooting/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const troubleshootings = [...getState().troubleshooting.troubleshootings]
      const newTroubleshootings = troubleshootings.filter(
        item => item._id !== id
      )

      dispatch({
        type: "SET_TROUBLESHOOTINGS",
        data: newTroubleshootings,
      })

      // Clear current troubleshooting if it's the one being deleted
      if (getState().troubleshooting.currentTroubleshooting?._id === id) {
        dispatch({
          type: "SET_CURRENT_TROUBLESHOOTING",
          data: null,
        })
      }

      return true
    } catch (error) {
      toast.error(`Error: ${error.response?.data?.message || error.message}`)
      throw error
    }
  }
}

export const setCurrentTroubleshooting = troubleshooting => ({
  type: "SET_CURRENT_TROUBLESHOOTING",
  data: troubleshooting,
})
